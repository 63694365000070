<template>
  <SettingsSidebar
    v-model:visible="settingsSidebarIsVisible"
  ></SettingsSidebar>
  <div :style="cssVars" class="page p-input-filled">
    <div class="header">
      <div class="logo"></div>
      <div></div>
      <Login @logged-in="$emit('logged-in')"/>
      <Button label="Settings" icon="pi pi-cog" class="p-button-rounded mr-4" @click="settingsSidebarIsVisible = true"></Button>
    </div>
    <div class="toolbar">
      <div class="views">
        <slot name="views"></slot>
      </div>
      <div class="actions">
        <slot name="actions"></slot>
      </div>
      <div class="toolbar-title">
        <slot name="title"></slot>
      </div>
    </div>
    <div class="main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Login from '@/components/Login.vue';
import SettingsSidebar from './components/SettingsSidebar.vue';

export default {
  emits: ['logged-in'],
  components: {
    Login,
    SettingsSidebar,
  },
  computed: {
    currentTheme() {
      return this.$store.state.navigation.currentTheme;
    },
    cssVars() {
      return {
        '--current-theme-url': this.currentTheme.url,
        '--img-supergraphics-url': `url(${this.currentTheme.url}/img/supergraphics.png)`,
        '--img-avalia-logo-url': `url(${this.currentTheme.url}/img/avalia-logo.png)`,
      };
    },
  },
  data() {
    return {
      settingsSidebarIsVisible: false,
    };
  },
  watch: {
    currentTheme(theme) {
      // const themeElement = document.getElementById('theme-link');
      // themeElement.setAttribute('href', `${theme.url}/theme.css`);
    },
  },
};
</script>

<style lang="scss">
.p-sidebar.avalia-sidebar-right {
  border-left: solid var(--primary-color);
  background-color: var(--surface-e);

  /*
  background-image: url(./wave.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  background-blend-mode: overlay;
  */
}

.p-sidebar.avalia-sidebar-left {
  border-right: solid var(--primary-color);
  background-color: var(--surface-e);
}

.p-sidebar.avalia-sidebar-top {
  border-bottom: solid var(--primary-color);
  background-color: var(--surface-e);
}

.p-sidebar.avalia-sidebar .p-sidebar-header .p-sidebar-close:focus, .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  box-shadow: 0 0 0 0px;
  background-color: var(--surface-e);
}

@media screen and (max-width: 64em) {
  .p-sidebar-left.p-sidebar-lg.avalia-sidebar, .p-sidebar-left.p-sidebar-md.avalia-sidebar, .p-sidebar-right.p-sidebar-lg.avalia-sidebar, .p-sidebar-right.p-sidebar-md.avalia-sidebar {
      width: 90%;
  }
}

.p-sidebar.avalia-sidebar-right {
.card::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.card {

  .group1 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  width: 100%;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    .pi {
      color: var(--surface-c);
      /* color: white; */
      font-size: 18rem;
    }
  }

  .title-bar {
    display: flex;
    align-items: center;
    .pi {
      margin-left: -1rem;
      padding: 1rem;
      cursor: pointer;
      font-size: 2rem;
      margin-right: 1rem;
      // color: rgba(60, 195, 236, 0.356);
      border: thin solid transparent;
    }
    .flagged {
      color: var(--danger-color);
    }
    .title {
      font-size: 2rem;
    }
  }

  .fields {
    /* padding-top: 3rem; */
    flex-grow: 1;

    .row {
      display: flex;
    }

    .property.slider {
      div {
        margin-bottom: 0.8rem;
      }
    }

    .property {
      margin-top: 2.8rem;
      margin-right: 2rem;

      .value {
        white-space: break-spaces;
        // font-size: 1.5rem;
        // font-weight: 100;
        span {
          margin-left: 1rem;
          opacity: 0.5;
        }
      }
      .legend {
        font-size: 1rem;
        opacity: 0.7;
        margin-bottom: 0.6rem;
      }
    }
  }

  .sectionTitle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8rem;
    margin-top: 3rem;

    .p-button {
      opacity: 0;
      margin-left: 1rem;
    }
  }

  .sectionTitle:hover {
    .p-button {
      opacity: 1.0;
    }
  }

  .icon {
    display: flex;
  }

  .enabled {
    color: var(--primary-color);
  }

  .disabled {
    color: var(--text-color-secondary);
  }

}

}
</style>

<style lang="scss" scoped>
.page:after {
  content: " ";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.6;
  background: var(--img-supergraphics-url);
  background-repeat: no-repeat;
  background-size: cover;
}

.page {
  // width: 100vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // min-height: 100vh;

  .header {
    display: flex;
    justify-content: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    height: 4rem;
    .logo {
      position: absolute;
      top: 1rem;
      left: 2rem;
      width: 18rem;
      height: 2rem;
      background-image: var(--img-avalia-logo-url);
      background-size: contain;
      background-repeat: no-repeat;
    }
    .p-dropdown {
      margin-right: 2rem;
    }
  }

  :deep(.toolbar) {
    display: flex;
    flex-direction: row;
    justify-items: center;
    // justify-content: space-between;
    padding-left: 2rem;
    //margin-right: 4rem;
    margin-bottom: 2rem;
    .views {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      i {
        color: var(--primary-color);
        font-size: 1.7rem;
        margin-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
      }
      .fa {
        color: var(--primary-color);
        margin-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
      }
      .current {
        color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
      }
    }
    .actions {
      display: flex;
      align-items: center;
      margin-right: 3rem;
      .p-component {
        margin-left: 1rem;
        // border: thin solid white;
      }
      .p-dropdown {
        padding: 0.1rem;
      }
      button {
        // color: white;
        padding: 0.6rem;
        // background: none;
      }
    }
    .toolbar-title {
      text-align: left;
      display: flex;
      align-items: center;
      font-size: 2rem;
      font-weight: 900;
    }
    .playbook-title {
      text-align: right;
      font-weight: 900;
      font-size: 2.8rem;
      text-transform: uppercase;
    }
  }

  .title {
    text-align: left;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .main {
    display: flex;
    flex-grow: 1;
  }

}
</style>
