<template>
  <div class="fields">
    <div class="property slider">
      <div class="legend">Inherent likelihood</div>
      <div>{{ label }} ({{ inherentLikelihood }})</div>
      <Slider v-model="likelihoodPercentage" :step="1" :min="0" :max="100" @change="$emit('propertyChanged', { 'propertyName': 'inherentLikelihood', 'propertyValue': inherentLikelihood})"/>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['propertyChanged'],
  props: {
    element: Object,
  },
  computed: {
    label() {
      return this.likelihoods[this.inherentLikelihood - 1];
    },
    inherentLikelihood() {
      return Math.min(5, Math.floor(this.likelihoodPercentage / 20) + 1);
    },
  },
  data() {
    return {
      likelihoodPercentage: (((this.element.inherentLikelihood || 1) - 1) / 5) * 100,
      likelihoods: ['rare', 'unlikely', 'possible', 'likely', 'certain'],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
