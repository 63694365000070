const module = {
  namespaced: true,
  state: {
    filters: {},
    currentTheme: {},
    displayNumbering: true,
    dragging: false, // drag and drop in progress
  },
  mutations: {
    SET_FILTER(state, { name, value }) {
      state.filters[name] = value;
    },
    SET_CURRENT_THEME(state, theme) {
      state.currentTheme = theme;
      localStorage.setItem('CURRENT_THEME', JSON.stringify(theme));
      const themeElement = document.getElementById('theme-link');
      themeElement.setAttribute('href', `${theme.url}/theme.css`);
    },
    SET_DISPLAY_NUMBERING(state, value) {
      state.displayNumbering = value;
      localStorage.setItem('DISPLAY_NUMBERING', JSON.stringify(value));
    },
    SET_DRAGGING(state, value) {
      state.dragging = value;
    },
  },
  actions: {
    init(context) {
      const userTheme = localStorage.getItem('CURRENT_THEME');
      if (userTheme) {
        context.commit('SET_CURRENT_THEME', JSON.parse(userTheme));
      } else {
        context.commit('SET_CURRENT_THEME', { name: 'Dark Blue', url: '/themes/avalia-dark-blue' });
      }

      const userDisplayNumbering = localStorage.getItem('DISPLAY_NUMBERING');
      if (userDisplayNumbering) {
        context.commit('SET_DISPLAY_NUMBERING', JSON.parse(userDisplayNumbering));
      } else {
        context.commit('SET_DISPLAY_NUMBERING', true);
      }
    },
  },
};

export default module;
