<template>
  <main-layout>
    <template v-slot:header>
      <div>Playbooks</div>
    </template>

    <template v-slot:views>
      <i
        @click="$router.push('/playbooks')"
        class="pi pi-home"
        :class="{ current: true }"
      ></i>
      <i
        @click="$router.push('/risks')"
        class="pi pi-exclamation-triangle"
        :class="{ current: false }"
      ></i>
    </template>

    <template v-slot:title>
      Your Playbooks
      <div class="filters">
        <InputText
          @input="applyFilters" type="text" placeholder="Filter by name..." v-model="filterByName" />
        <AutoComplete
          @item-select="applyFilters"
          @item-unselect="applyFilters"
          @clear="applyFilters"
          panelClass="panel-class"
          :multiple="true" placeholder="Tags" v-model="filterByTags" :suggestions="tags" @complete="searchTag($event)" field="name"/>
      </div>
    </template>

    <template v-slot>
      <div class="view">
        <div class="playbooks">
          <div
            v-for="playbook in playbooks"
            :key="playbook.id"
            class="card"
            @click="selectPlaybook(playbook)"
          >
            <div class="title">
              {{ playbook.name }}
            </div>
            <div class="icons">
              <div class="icon warning">
                <i v-if="playbook.atRisk" class="pi pi-flag"></i>
              </div>
              <div :class="{ icon: true }">
                <i class="pi pi-book"></i>
              </div>
            </div>
          </div>
          <!-- the placeholders are used to left-align the cards on the last row; see https://medium.com/developedbyjohn/equal-width-flex-items-a5ba1bfacb77 -->
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
        </div>
      </div>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      filterByName: '',
      filterByTags: null,
      tags: null,
    };
  },
  computed: {
    assess() {
      return this.$store.state.assess;
    },
    playbooks() {
      return this.$store.state.assess.playbooks;
    },
    projectTags() {
      return this.$store.state.assess.projectTags;
    },
  },
  methods: {
    applyFilters() {
      this.$store.dispatch('assess/fetchPlaybooks', { filterByName: this.filterByName, filterByTags: this.filterByTags });
    },
    selectPlaybook(playbook) {
      this.$router.push(`/playbooks/${playbook.id}`);
    },
    searchTag(event) {
      if (event.query?.trim() === '?') {
        this.tags = [...this.projectTags];
      } else {
        this.tags = this.projectTags.filter((tag) => {
          return tag.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },
    searchCountry(event) {
      this.filteredCountriesBasic = [...this.filteredCountriesBasic];
    },
  },
};
</script>

<style lang="scss">
  body .panel-class {
    border-radius: 0px;
    background-color: #00567f;
    color: black;
  }
</style>
<style lang="scss" scoped>

.filters {
  display: flex;
  input {
    margin-left: 3rem;
  }
  input:focus {
    box-shadow: none;
  }
  .p-autocomplete {
    margin-left: 2rem;
  }
}

.group-title {
  padding-left: 2rem;
  font-size: 1.8rem;
  color: #ffa200;
  margin-bottom: 2rem;
  text-align: left;
}

.playbooks {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: -1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .card:hover {
    border: 2px solid var(--text-color);
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    flex-grow: 1;
    z-index: 1;
    font-size: 1.2rem;
    width: 16rem;
    min-height: 8rem;
    text-align: left;
    border: 2px solid var(--primary-color-transparent);
    background-color: var(--surface-a);
    padding: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    border-top-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    .title {
      text-align: left;
    }
    .icons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .icon {
        width: 2rem;
        height: 2rem;
        color: var(--primary-color);
        /* border: thin solid rgba(255, 166, 0, 0.404); */
        align-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: center;
        .pi {
          font-size: 1.7rem;
        }
      }
    }
  }
  .placeholder {
    flex-grow: 1;
    width: 16rem;
    border: none;
    margin-right: 1rem;
  }
}
</style>
