/* eslint-disable no-restricted-properties */
import * as axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

function saveToLocalStorage(state) {
  localStorage.setItem('avalia/butterfly/situations', JSON.stringify(state.situations));
}

function loadFromLocalStorage(state) {
  const stored = localStorage.getItem('avalia/butterfly/situations');
  if (stored) {
    state.situations = JSON.parse(stored);
  }
}

const module = {
  namespaced: true,
  state: {
    currentSituation: undefined,
    situations: {},
  },
  mutations: {
    SET_SITUATIONS(state, situations) {
      state.situations = situations;
      saveToLocalStorage(state);
    },
    CREATE_SITUATION(state, newSituationId) {
      state.situations[newSituationId] = {
        id: newSituationId,
        label: 'Untitled situation',
        event: {
          id: uuidv4(),
          label: 'Untitled event',
        },
        context: {
          id: uuidv4(),
          label: 'Untitled context',
        },
        seedToEventChains: [],
        eventToOutcomeChains: [],
      };
      saveToLocalStorage(state);
      return state.situations[newSituationId];
    },
    DELETE_SITUATION(state, situation) {
      if (state.situations[situation.id]) {
        delete state.situations[situation.id];
      }
      saveToLocalStorage(state);
    },
    CLONE_SITUATION(state, situation) {
      const clone = JSON.parse(JSON.stringify(situation));
      clone.id = uuidv4();
      clone.label = `Copy of ${situation.label}`;
      clone.event.id = uuidv4();
      clone.context.id = uuidv4();
      clone.seedToEventChains.forEach((c) => {
        c.seed.id = uuidv4();
        c.proactiveActions.forEach((a) => a.id = uuidv4());
      });
      clone.eventToOutcomeChains.forEach((c) => {
        c.outcome.id = uuidv4();
        c.reactiveActions.forEach((a) => a.id = uuidv4());
      });
      state.situations[clone.id] = clone;
      saveToLocalStorage(state);
    },
    SET_CURRENT_SITUATION(state, situation) {
      if (!state.situations[situation.id]) {
        state.situations[situation.id] = situation;
      }
      state.currentSituation = situation;
      saveToLocalStorage(state);
    },
    CREATE_SEED(state, situation) {
      situation.seedToEventChains.push({
        id: uuidv4(),
        seed: {
          id: uuidv4(),
          label: 'Cause',
        },
        proactiveActions: [],
      });
      saveToLocalStorage(state);
    },
    CREATE_OUTCOME(state, situation) {
      situation.eventToOutcomeChains.push({
        id: uuidv4(),
        outcome: {
          id: uuidv4(),
          label: 'Outcome',
        },
        reactiveActions: [],
      });
      saveToLocalStorage(state);
    },
    CREATE_PROACTIVE_ACTION(state, seedToEventChain) {
      seedToEventChain.proactiveActions.push({
        id: uuidv4(),
        label: 'Proactive action',
      });
      saveToLocalStorage(state);
    },
    CREATE_REACTIVE_ACTION(state, eventToOutcomeChain) {
      eventToOutcomeChain.reactiveActions.push({
        id: uuidv4(),
        label: 'Reactive action',
      });
      saveToLocalStorage(state);
    },
    DELETE_SEED(state, { situationId, elementId }) {
      const situation = state.situations[situationId];
      const foundIndex = situation.seedToEventChains.findIndex((c) => c.seed.id === elementId);
      if (foundIndex !== -1) {
        situation.seedToEventChains.splice(foundIndex, 1);
      }
      saveToLocalStorage(state);
    },
    DELETE_OUTCOME(state, { situationId, elementId }) {
      const situation = state.situations[situationId];
      const foundIndex = situation.eventToOutcomeChains.findIndex((c) => c.outcome.id === elementId);
      if (foundIndex !== -1) {
        situation.eventToOutcomeChains.splice(foundIndex, 1);
      }
      saveToLocalStorage(state);
    },
    DELETE_PROACTIVE_ACTION(state, { situationId, elementId }) {
      const situation = state.situations[situationId];
      situation.seedToEventChains.some((c) => {
        const foundIndex = c.proactiveActions.findIndex((a) => a.id === elementId);
        if (foundIndex !== -1) {
          c.proactiveActions.splice(foundIndex, 1);
          return true;
        }
        return false;
      });
      saveToLocalStorage(state);
    },
    DELETE_REACTIVE_ACTION(state, { situationId, elementId }) {
      const situation = state.situations[situationId];
      situation.eventToOutcomeChains.some((c) => {
        const foundIndex = c.reactiveActions.findIndex((a) => a.id === elementId);
        if (foundIndex !== -1) {
          c.reactiveActions.splice(foundIndex, 1);
          return true;
        }
        return false;
      });
      saveToLocalStorage(state);
    },
    UPDATE_ELEMENT_PROPERTY(state, {
      situation, element, propertyName, propertyValue,
    }) {
      element[propertyName] = propertyValue;
      saveToLocalStorage(state);
    },
    RECOMPUTE_SITUATION(state, situationId) {
      const BASE = 5;
      const impacts = ['neglectable', 'minor', 'moderate', 'critical', 'catastrophic'];
      const likelihoods = ['rare', 'unlikely', 'possible', 'likely', 'certain'];

      const situation = state.situations[situationId];
      if (situation) {
        let totalInherentLikelihood = 0;
        let totalResidualLikelihood = 0;
        situation.seedToEventChains.forEach((c) => {
          c.seed.inherentLikelihood = c.seed.inherentLikelihood || 3;
          c.proactiveActions.forEach((a) => { a.currentEffectiveness = a.currentEffectiveness || 0; a.targetEffectiveness = a.targetEffectiveness || 0; });
          const combinedCurrentEffectiveness = c.proactiveActions.map((a) => a.currentEffectiveness).reduce((previousValue, currentValue) => { return previousValue + currentValue; }, 0);
          c.seed.residualLikelihood = Math.max(1, Math.min(5, Math.round(c.seed.inherentLikelihood * (1 - combinedCurrentEffectiveness / 100))));
          c.seed.residualLikelihoodLabel = likelihoods[c.seed.residualLikelihood - 1];
          totalInherentLikelihood += Math.pow(BASE, c.seed.inherentLikelihood);
          totalResidualLikelihood += Math.pow(BASE, c.seed.inherentLikelihood * (1 - combinedCurrentEffectiveness / 100));
        });

        situation.event.inherentLikelihood = Math.max(1, Math.min(5, Math.round(Math.log(totalInherentLikelihood) / Math.log(BASE))));
        situation.event.residualLikelihood = Math.max(1, Math.min(5, Math.round(Math.log(totalResidualLikelihood) / Math.log(BASE))));
        situation.event.inherentLikelihoodLabel = likelihoods[situation.event.inherentLikelihood - 1];
        situation.event.residualLikelihoodLabel = likelihoods[situation.event.residualLikelihood - 1];

        let totalInherentImpact = 0;
        let totalResidualImpact = 0;
        situation.eventToOutcomeChains.forEach((c) => {
          c.outcome.inherentImpact = c.outcome.inherentImpact || 3;
          c.reactiveActions.forEach((a) => { a.currentEffectiveness = a.currentEffectiveness || 0; a.targetEffectiveness = a.targetEffectiveness || 0; });
          const combinedCurrentEffectiveness = c.reactiveActions.map((a) => a.currentEffectiveness).reduce((previousValue, currentValue) => { return previousValue + currentValue; }, 0);
          c.outcome.residualImpact = Math.max(1, Math.min(5, Math.round(c.outcome.inherentImpact * (1 - combinedCurrentEffectiveness / 100))));
          c.outcome.residualImpactLabel = impacts[c.outcome.residualImpact - 1];
          totalInherentImpact += Math.pow(BASE, c.outcome.inherentImpact);
          totalResidualImpact += Math.pow(BASE, c.outcome.inherentImpact * (1 - combinedCurrentEffectiveness / 100));
        });

        situation.event.inherentImpact = Math.max(1, Math.min(5, Math.round(Math.log(totalInherentImpact) / Math.log(BASE))));
        situation.event.residualImpact = Math.max(1, Math.min(5, Math.round(Math.log(totalResidualImpact) / Math.log(BASE))));
        situation.event.inherentImpactLabel = impacts[situation.event.inherentImpact - 1];
        situation.event.residualImpactLabel = impacts[situation.event.residualImpact - 1];

        situation.event.inherentScore = (situation.event.inherentLikelihood) * (situation.event.inherentImpact);
        situation.event.residualScore = (situation.event.residualLikelihood) * (situation.event.residualImpact);

        const severity = (score) => {
          if (score < 4) { return 'Low'; }
          if (score < 7) { return 'Moderate'; }
          if (score < 15) { return 'High'; }
          return 'Critical';
        };
        situation.event.inherentSeverity = severity(situation.event.inherentScore);
        situation.event.residualSeverity = severity(situation.event.residualScore);
      }
      saveToLocalStorage(state);
    },
    RECOMPUTE_ALL_SITUATIONS(state) {
      // eslint-disable-next-line no-restricted-syntax
      for (const situation of Object.values(state.situations)) {
        state.commit('RECOMPUTE_SITUATION', situation);
      }
      saveToLocalStorage(state);
    },
  },
  actions: {
    init(context) {
      console.log('init');
      loadFromLocalStorage(context.state);
      context.dispatch('getSituation', '8c53a12d-af95-467b-844d-815f5bc256fe');
    },
    createSituation(context) {
      const newSituationId = uuidv4();
      context.commit('CREATE_SITUATION', newSituationId);
      context.commit('RECOMPUTE_SITUATION', newSituationId);
    },
    setSituations(context, situations) {
      context.commit('SET_SITUATIONS', situations);
    },
    getSituation(context, situationId) {
      const alreadyLoaded = context.state.situations[situationId];
      if (alreadyLoaded) {
        context.commit('SET_CURRENT_SITUATION', alreadyLoaded);
        context.commit('RECOMPUTE_SITUATION', situationId);
        return alreadyLoaded;
      }
      return axios.get(`/data/situations/${situationId}.json`).then((response) => {
        context.commit('SET_CURRENT_SITUATION', response.data);
        context.commit('RECOMPUTE_SITUATION', response.data.id);
        return response.data;
      });
    },
    deleteSituation(context, situation) {
      context.commit('DELETE_SITUATION', situation);
    },
    cloneSituation(context, situation) {
      context.commit('CLONE_SITUATION', situation);
    },
    createSeed(context, situation) {
      context.commit('CREATE_SEED', situation);
      context.commit('RECOMPUTE_SITUATION', situation.id);
    },
    createOutcome(context, situation) {
      context.commit('CREATE_OUTCOME', situation);
    },
    createProactiveAction(context, { situation, seedToEventChain }) {
      context.commit('CREATE_PROACTIVE_ACTION', seedToEventChain);
      context.commit('RECOMPUTE_SITUATION', situation.id);
    },
    createReactiveAction(context, { situation, eventToOutcomeChain }) {
      context.commit('CREATE_REACTIVE_ACTION', eventToOutcomeChain);
      context.commit('RECOMPUTE_SITUATION', situation.id);
    },
    deleteSeed(context, { situationId, elementId }) {
      context.commit('DELETE_SEED', { situationId, elementId });
      context.commit('RECOMPUTE_SITUATION', situationId);
    },
    deleteOutcome(context, { situationId, elementId }) {
      context.commit('DELETE_OUTCOME', { situationId, elementId });
      context.commit('RECOMPUTE_SITUATION', situationId);
    },
    deleteProactiveAction(context, { situationId, elementId }) {
      context.commit('DELETE_PROACTIVE_ACTION', { situationId, elementId });
      context.commit('RECOMPUTE_SITUATION', situationId);
    },
    deleteReactiveAction(context, { situationId, elementId }) {
      context.commit('DELETE_REACTIVE_ACTION', { situationId, elementId });
      context.commit('RECOMPUTE_SITUATION', situationId);
    },
    updateElementProperty(context, {
      situation, element, propertyName, propertyValue,
    }) {
      context.commit('UPDATE_ELEMENT_PROPERTY', {
        situation, element, propertyName, propertyValue,
      });
      context.commit('RECOMPUTE_SITUATION', situation.id);
    },
  },
};

export default module;
