<template>
  <div :class="{ 'archived': container.isArchived }">

  <div class="category-title"
    @click="selectCategory(container)"
    @contextmenu="showContextMenuForCategory($event, container)"
  >
      <ContextMenu :model="categoryMenuModel" ref="categoryContextMenu" />
      <ContextMenu :model="activityMenuModel" ref="activityContextMenu" />
      <div class="text"><span v-if="displayNumbering">{{ container.autoNumbering.join('.') }}{{ container.autoNumbering.length > 0 ? '.' : ''}} </span> {{ container.title }}</div>
  </div>
  <table v-if="container.activities.length > -0">
    <tr
      v-for="activity in container.activities"
      :key="activity.key"
      :class="{ 'archived': activity.isArchived }"
      @click="selectActivity(activity)"
      @contextmenu="showContextMenuForActivity($event, activity)"
    >
      <td>
        <div class="icon warning">
          <i v-if="activity.atRisk" class="pi pi-flag"></i>
        </div>
      </td>
      <td>
        <div class="icon">
          <i v-if="activity.activityType === 'COORDINATED'" class="pi pi-users"></i>
        </div>
      </td>
      <td>{{ activity.activityCode }}</td>
      <td>{{ activity.title }}</td>
      <td :style="{ color: colorFromActivity(activity) }">
        {{ activity.status }}
      </td>
      <td>{{ activity.owner }}</td>
      <td>
        {{ activity.clientFocalPoint }}
      </td>
      <td :style="{ color: startDateColor(activity) }">
        {{ activity.startDate }}
      </td>
      <td :style="{ color: dueDateColor(activity) }">{{ activity.dueDate }}</td>
      <!--
      <td>
        <div class="counter" v-if="activity.milestones?.length">
          <i class="pi pi-comment"></i> {{ activity.milestones?.length }}
        </div>
      </td>
      <td>
        <div class="counter" v-if="activity.resources?.length">
          <i class="pi pi-file"></i> {{ activity.resources?.length }}
        </div>
      </td>
      -->
    </tr>
  </table>
  <template v-for="category in container.categories" :key="category.key">
    <!--
    <div @click="selectCategory(category)" class="category-title">
      <div class="text"><span v-if="displayNumbering">{{ category.autoNumbering.join('.') }}. </span>{{ category.title }}</div>
      <div class="group">
        <Button
          label="Add category"
          @click.stop="addCategory(category.id)"
          class="p-button-outlinedx mr-2 avalia"
        />
        <Button
          label="Add activity"
          @click.stop="addActivity(category.id)"
          class="p-button-outlinedx mr-2 avalia"
        />
        <Button
          label="Archive category"
          @click.stop="archiveCategory(category.id)"
          class="p-button-outlinedx mr-2 avalia"
        />
      </div>
    </div>
    -->
    <playbook-view-table-category
      :container="category"
      @select-activity="selectActivity"
      @select-category="selectCategory"
    />
  </template>
  </div>
</template>

<script>
export default {
  emits: ['select-activity', 'select-category'],
  name: 'PlaybookViewTableCategory',
  props: {
    container: Object,
  },
  computed: {
    navigation() {
      return this.$store.state.navigation;
    },
    displayNumbering() {
      return this.$store.state.navigation.displayNumbering;
    },
    filter() {
      return this.$store.state.navigation.filters['playbook.view.global'];
    },
  },
  data() {
    return {
      activityMenuModel: [
        {
          label: 'Edit',
          icon: 'pi pi-fw pi-pencil',
          command: () => {
            this.selectActivity(this.activityForContextMenu);
            this.activityForContextMenu = undefined;
          },
        },
        {
          label: 'Archive',
          icon: 'pi pi-fw pi-eye-slash',
          visible: () => !this.activityForContextMenu.isArchived,
          command: () => {
            this.archiveActivity(this.activityForContextMenu.id);
            this.activityForContextMenu = undefined;
          },
        },
        {
          label: 'Unarchive',
          icon: 'pi pi-fw pi-eye',
          visible: () => this.activityForContextMenu.isArchived,
          command: () => {
            this.unarchiveActivity(this.activityForContextMenu.id);
            this.activityForContextMenu = undefined;
          },
        },
      ],
      activityForContextMenu: undefined,
      categoryMenuModel: [
        {
          label: 'Edit',
          icon: 'pi pi-fw pi-pencil',
          visible: () => this.categoryForContextMenu,
          command: () => {
            this.selectCategory(this.categoryForContextMenu);
            this.categoryForContextMenu = undefined;
          },
        },
        {
          label: 'Add activity',
          icon: 'pi pi-fw pi-plus',
          command: () => {
            this.addActivity(this.categoryForContextMenu?.id);
            this.categoryForContextMenu = undefined;
          },
        },
        {
          label: 'Add category',
          icon: 'pi pi-fw pi-plus',
          command: () => {
            this.addCategory(this.categoryForContextMenu?.id);
            this.categoryForContextMenu = undefined;
          },
        },
        {
          label: 'Archive',
          icon: 'pi pi-fw pi-eye-slash',
          visible: () => this.categoryForContextMenu && !this.categoryForContextMenu.isArchived,
          command: () => {
            this.archiveCategory(this.categoryForContextMenu.id);
            this.categoryForContextMenu = undefined;
          },
        },
        {
          label: 'Unarchive',
          icon: 'pi pi-fw pi-eye',
          visible: () => this.categoryForContextMenu && this.categoryForContextMenu.isArchived,
          command: () => {
            this.unarchiveCategory(this.categoryForContextMenu.id);
            this.categoryForContextMenu = undefined;
          },
        },
      ],
      categoryForContextMenu: undefined,
    };
  },
  methods: {
    selectActivity(activity) {
      this.$emit('select-activity', activity);
    },
    selectCategory(category) {
      this.$emit('select-category', category);
    },
    showContextMenuForCategory(event, container) {
      if (container._id) {
        this.categoryForContextMenu = undefined; // it is a playbook
      } else {
        this.categoryForContextMenu = container;
      }
      this.$refs.categoryContextMenu.show(event);
    },
    showContextMenuForActivity(event, activity) {
      this.activityForContextMenu = activity;
      this.$refs.activityContextMenu.show(event);
    },
    percentageComplete(activity) {
      const val = activity.additionalProperties['Percentage Complete']?.value ?? 0;
      return val * 100;
    },
    colorFromActivity(activity) {
      switch (activity.status) {
        case 'In Progress':
          return 'var(--secondary-color)';
        case 'Completed':
          return 'var(--primary-color)';
        case 'Cancelled':
          return 'var(--text-color-secondary)';
        case 'On Hold':
          return 'var(--text-color-secondary)';
        default:
          return 'var(--text-color)';
      }
    },
    startDateColor(activity) {
      if (activity.lateToStart) {
        return 'var(--warning-color)';
      }
      return 'var(--primary-color)';
    },
    dueDateColor(activity) {
      if (activity.lateToFinish) {
        return 'var(--warning-color)';
      }
      return 'var(--primary-color)';
    },
    addCategory(categoryId) {
      this.$store.dispatch('assess/createCategory', {
        playbook: this.$store.state.assess.playbook,
        data: {
          title: 'Untitled category',
        },
        containerId: categoryId,
      });
    },
    addActivity(categoryId) {
      this.$store.dispatch('assess/createActivity', {
        playbook: this.$store.state.assess.playbook,
        data: {
          title: 'Untitled activity',
        },
        containerId: categoryId,
      });
    },
    archiveCategory(categoryId) {
      this.$store.dispatch('assess/archiveCategory', {
        playbook: this.$store.state.assess.playbook,
        categoryId,
      });
    },
    unarchiveCategory(categoryId) {
      this.$store.dispatch('assess/unarchiveCategory', {
        playbook: this.$store.state.assess.playbook,
        categoryId,
      });
    },
    archiveActivity(activityId) {
      this.$store.dispatch('assess/archiveActivity', {
        playbook: this.$store.state.assess.playbook,
        activityId,
      });
    },
    unarchiveActivity(activityId) {
      this.$store.dispatch('assess/unarchiveActivity', {
        playbook: this.$store.state.assess.playbook,
        activityId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.category-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 1.1rem;
  font-weight: bold;
  background: var(--surface-a);
  color: var(--primary-color);
  margin-bottom: -2px;
  margin-top: 3.8rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 0.6rem;
  cursor: pointer;
  border: 2px solid var(--primary-color);

  button.avalia {
    opacity: 0;
  }
}

.table {
.archived {
  color: var(--primary-color-transparent);
  .category-title {
    color: var(--primary-color-transparent);
    border: 2px solid var(--primary-color-transparent);
  }
  table {
    border: 2px solid var(--primary-color-transparent);
  }
}
}

.table {
  background-color: var(--surface-a);
  table {
    margin-bottom: 2rem;
    cursor: pointer;
    font-size: 1.1rem;
    width: 100%;
    max-width: 100%;
    text-align: left;
    border-collapse: collapse;
    border: 2px solid var(--primary-color);
    table-layout: fixed;

    tr:hover {
      background-color: var(--primary-color-transparent);
      button {
        opacity: 1;
      }
    }

    tr.archived {
      color: var(--primary-color-transparent);
    }

    tr {
      button {
        opacity: 0;
      }
      /* border-bottom: thin solid var(--primary-color-transparent); */

      td {
        white-space: nowrap;
        padding: 0.6rem;
        text-overflow: ellipsis;
        overflow: hidden;
        border: thin solid var(--primary-color-transparent);
        border-top: thin solid transparent;
        background-color: var(--surface-a);

        .counter {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }
      }

      td:nth-child(1) {
        width: 1.5rem;
        color: var(--danger-color);
        text-align: center;
      }
      td:nth-child(2) {
        width: 1.5rem;
        text-align: center;
      }
      td:nth-child(3) {
        width: 3rem;
        text-align: center;
      }
      td:nth-child(4) {
        width: 14rem;
        text-align: left;
      }
      td:nth-child(5) {
        width: 4rem;
        text-align: center;
      }
      td:nth-child(6) {
        width: 6rem;
        text-align: center;
      }
      td:nth-child(7) {
        width: 6rem;
        text-align: center;
      }
      td:nth-child(8) {
        width: 6rem;
        text-align: center;
      }
      td:nth-child(9) {
        width: 6rem;
        text-align: center;
      }
    }
  }
}
</style>
