<template>
  <div>Redirecting after OAuth callback...</div>
</template>

<script>
import store from '@/store';

export default {
  beforeRouteEnter(to, from, next) {
    const { code, state } = to.query;
    if (!code) {
      next();
    } else {
      store.dispatch('assess/init').then(() => {
        store.dispatch('smartsheet/exchangeCodeForTokens', code).then(() => {
          next({
            path: state,
            query: {},
          });
        });
      });
    }
  },
};
</script>
