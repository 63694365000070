<template>
      <div class="fields">
        <!--
        <div class="property">
          <div class="property">
            <div class="legend">Risk score</div>
              <p v-html='formula'></p>
          </div>
        </div>
        -->
      </div>
</template>

<script>
export default {
  emits: ['propertyChanged'],
  props: {
    situation: Object,
    element: Object,
  },
  computed: {
    formula() {
      const base = 10;
      let result = '<b>Causes and proactive actions that reduce likelihood:</b>';
      // const likeliHoods = this.situation.seedToEventChains.map((c) => c.seed.likelihood);
      let totalLikelihood = 0;
      let totalResidualLikelihood = 0;
      this.situation.seedToEventChains.forEach((c) => {
        const effectiveness = c.proactiveActions.map((a) => a.currentEffectiveness).reduce((previousValue, currentValue) => { return previousValue + currentValue; }, 0);
        result += `<ul><b>${c.seed.label} : inherent likelihood=${c.seed.likelihood}, effect of actions=-${effectiveness}%, residual likelihood=${c.seed.likelihood * (1 - effectiveness / 100)}</b>`;
        // eslint-disable-next-line no-restricted-properties
        totalLikelihood += Math.pow(base, c.seed.likelihood);
        // eslint-disable-next-line no-restricted-properties
        totalResidualLikelihood += Math.pow(base, c.seed.likelihood * (1 - effectiveness / 100));
        c.proactiveActions.forEach((a) => {
          result += `<li> ${a.label}: <b>- ${a.currentEffectiveness}%</b></li>`;
        });
        result += '</ul>';
      });
      result += '<b>Consequences and reactive actions that reduce impact:</b>';
      let totalImpact = 0;
      let totalResidualImpact = 0;
      this.situation.eventToOutcomeChains.forEach((c) => {
        const effectiveness = c.reactiveActions.map((a) => a.currentEffectiveness).reduce((previousValue, currentValue) => { return previousValue + currentValue; }, 0);
        result += `<ul><b>${c.outcome.label} : inherent impact=${c.outcome.impact}, effect of actions=-${effectiveness}%, residual impact=${c.outcome.impact * (1 - effectiveness / 100)}</b>`;
        // eslint-disable-next-line no-restricted-properties
        totalImpact += Math.pow(base, c.outcome.impact);
        // eslint-disable-next-line no-restricted-properties
        totalResidualImpact += Math.pow(base, c.outcome.impact * (1 - effectiveness / 100));
        c.reactiveActions.forEach((a) => {
          result += `<li> ${a.label}: <b>- ${a.currentEffectiveness}%</b></li>`;
        });
        result += '</ul>';
      });
      totalLikelihood = Math.round(Math.log(totalLikelihood) / Math.log(base));
      totalResidualLikelihood = Math.round(Math.log(totalResidualLikelihood) / Math.log(base));
      totalImpact = Math.round(Math.log(totalImpact) / Math.log(base));
      totalResidualImpact = Math.round(Math.log(totalResidualImpact) / Math.log(base));
      let prefix = `<b>Aggregate likelihood (residual / inherent)</b>: ${totalResidualLikelihood} / ${totalLikelihood}<br/>`;
      prefix += `<b>Aggregate impact (residual / inherent)</b>: ${totalResidualImpact} / ${totalImpact}<br/>`;
      return `${prefix}<br/>${result}<br/>`;
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
