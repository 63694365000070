import { library } from '@fortawesome/fontawesome-svg-core';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { createApp } from 'vue';
import Chartist from 'chartist';

// import VueClipboard from "@soerenmartius/vue3-clipboard";
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import MultiSelect from 'primevue/multiselect';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import Slider from 'primevue/slider';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Textarea from 'primevue/textarea';
import ToastService from 'primevue/toastservice';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import router from './router';
import store from './store';
import App from './App.vue';

import 'primeflex/primeflex.css';

// import '@/assets/themes/avalia-dark/theme.css';
// import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css';
// import 'primevue/resources/themes/vela-purple/theme.css';
// import '@/assets/themes/avalia-light-purple/theme.css';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const app = createApp(App)
  //  .use(VueClipboard)
  .use(store)
  .use(router);

app.component('AutoComplete', AutoComplete);
app.component('Button', Button);
app.component('Card', Card);
app.component('Calendar', Calendar);
app.component('Checkbox', Checkbox);
app.component('Column', Column);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('InputText', InputText);
app.component('InputSwitch', InputSwitch);
app.component('Listbox', Listbox);
app.component('MultiSelect', MultiSelect);
app.component('SelectButton', SelectButton);
app.component('Sidebar', Sidebar);
app.component('Slider', Slider);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Textarea', Textarea);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.use(PrimeVue, { ripple: true });
app.directive('ripple', Ripple);
app.use(ToastService);

app.config.globalProperties.$chartist = Chartist;

library.add(faProjectDiagram);

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
