<template>
  <div class="main">
    <div class="table">
      <DataTable
        :value="situationsAsArray"
        v-model:filters="filters"
        :globalFilterFields="['event.label']"
        filterDisplay="menu"
        sortMode="single"
        class="p-datatable-sm"
        :paginator="true"
        :rows="10"
        responsiveLayout="scroll"
      >
        <template #header>
          <div class="table-header">
            <Button
              label="Add situation"
              @click="addRisk"
              class="p-button-outlined mr-2"
            />
            <div>
              <div class="table-actions">
                <FileUpload class="mr-2" chooseLabel="Import" mode="basic" name="demo[]" accept="application/json" :customUpload="true" @uploader="uploader" :auto="true"/>
                <Button
                  label="Export"
                  @click="exportJson"
                  class="p-button-outlined mr-2"
                />
              </div>
            </div>
          </div>
        </template>

        <Column field="label" header="Situation" headerStyle="width: 30%">
          <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder=""/>
          </template>
        </Column>
        <Column field="event.label" header="Event" headerStyle="width: 30%">
          <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder=""/>
          </template>
        </Column>
        <Column field="event.residualLikelihood" header="Residual likelihood" dataType="numeric" :sortable="true">
          <template #filter="{ filterModel, filterCallback }">
              <InputText type="number" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder=""/>
          </template>
            <template #body="slotProps">
                {{ slotProps.data.event.residualLikelihoodLabel }} (from {{ slotProps.data.event.inherentLikelihoodLabel }})
            </template>
        </Column>
        <Column field="event.residualImpact" header="Residual impact" dataType="numeric" :sortable="true">
          <template #filter="{ filterModel, filterCallback }">
              <InputText type="number" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder=""/>
          </template>
            <template #body="slotProps">
                {{ slotProps.data.event.residualImpactLabel }} (from {{ slotProps.data.event.inherentImpactLabel }})
            </template>
        </Column>
        <Column field="event.residualScore" header="Score" dataType="numeric" :sortable="true">
          <template #filter="{ filterModel, filterCallback }">
              <InputText type="number" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder=""/>
          </template>
            <template #body="slotProps">
                {{ slotProps.data.event.residualScore }} (from {{ slotProps.data.event.inherentScore }})
            </template>
        </Column>
        <Column field="event.residualSeverity" header="Severity" dataType="text" :sortable="true">
          <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder=""/>
          </template>
            <template #body="slotProps">
                {{ slotProps.data.event.residualSeverity }} (from {{ slotProps.data.event.inherentSeverity }})
            </template>
        </Column>
        <Column :exportable="false">
            <template #body="slotProps">
              <div class="table-actions">
                  <Button icon="pi pi-trash" class="p-button-text" @click="deleteSituation(slotProps.data)" />
                  <Button icon="pi pi-clone" class="p-button-text" @click="cloneSituation(slotProps.data)" />
                  <Button icon="pi pi-pencil" class="p-button-text" @click="editSituation(slotProps.data)" />
              </div>
            </template>
        </Column>
      <!--
        <Column field="title" header="Title">
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by title"
            />
          </template>
        </Column>
        <Column field="likelihood" header="Likelihood"></Column>
        <Column field="impact" header="Impact"></Column>
        <Column field="score" header="Score"></Column>
        -->
      </DataTable>
      <!--
      <table>
        <thead>
          <th>Risk ID</th>
          <th>Title</th>
          <th>Likelihood</th>
          <th>Impact</th>
          <th>Score</th>
        </thead>
        <tr v-for="risk in risks" :key="risk.id">
          <td>{{ risk.id }}</td>
          <td>{{ risk.title }}</td>
          <td>{{ risk.likelihood }}</td>
          <td>{{ risk.impact }}</td>
          <td>{{ risk.likelihood * risk.impact }}</td>
        </tr>
      </table>
      -->
    </div>
    <div class="chart">
      <svg :id="'svg_' + 0"></svg>
    </div>
  </div>

  <!--
  <div>

  </div>
  -->
</template>

<script>
// import { FilterOperator } from 'primevue/api';
import { FilterMatchMode } from 'primevue/api';
import * as d3 from 'd3';

export default {
  emits: ['select-risk'],
  components: {},
  computed: {
    situations() {
      return this.$store.state.butterfly.situations;
    },
    situationsAsArray() {
      return Array.from(Object.values(this.$store.state.butterfly.situations));
    },
    matrix() {
      const cells = [];
      this.likelihoods.forEach((likelihood, xIndex) => {
        this.impacts.forEach((impact, yIndex) => {
          const cell = {
            x: xIndex,
            y: yIndex,
            likelihoodValue: xIndex + 1,
            impactValue: yIndex + 1,
            likelihoodLabel: this.likelihoods[xIndex],
            impactLabel: this.impacts[yIndex],
          };
          cell.factorMult = (cell.x + 1) * (cell.y + 1);
          cells.push(cell);
        });
      });
      return cells;
    },
  },
  data() {
    return {
      filters: {
        label: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        'event.label': {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        redisualLikelihood: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        residualImpact: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        score: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        severity: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
      },
      likelihoods: ['rare', 'unlikely', 'possible', 'likely', 'certain'],
      impacts: ['neglectable', 'minor', 'moderate', 'critical', 'catastrophic'],
    };
  },
  methods: {
    selectRisk(risk) {
      this.$emit('select-risk', risk);
    },
    addRisk() {
      this.$store.dispatch('butterfly/createSituation');
    },
    editSituation(situation) {
      this.$router.push(`/risks/situations/${situation.id}`);
    },
    deleteSituation(situation) {
      this.$store.dispatch('butterfly/deleteSituation', situation);
    },
    cloneSituation(situation) {
      this.$store.dispatch('butterfly/cloneSituation', situation);
    },
    chartSize() {
      const svg = d3.select(`#svg_${0}`);
      const chartWidth = svg.node().clientWidth;
      const chartHeight = svg.node().clientHeight;
      return [chartWidth, chartHeight];
    },
    importJson() {
    },
    uploader(event) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = JSON.parse(e.target.result);
        this.$store.dispatch('butterfly/setSituations', fileContent);
      };
      reader.readAsText(event.files[0]);
    },
    exportJson() {
      const text = JSON.stringify(this.situations);
      const filename = 'risk-register.json';
      const element = document.createElement('a');
      element.setAttribute('href', `data:application/json;charset=utf-8,${encodeURIComponent(text)}`);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    updateChart() {
      const svg = d3.select(`#svg_${0}`);
      const chartWidth = svg.node().clientWidth;
      const chartHeight = svg.node().clientHeight;
      const cellWidth = (chartWidth + 0) / this.likelihoods.length;
      const cellHeight = (chartHeight + 0) / this.impacts.length;
      svg.attr('viewBox', [0, 0, chartWidth, chartHeight]);
      svg.selectAll('*').remove();
      const svgFrame = svg.append('g');

      // const radius = 10;

      const cells = svgFrame
        .selectAll('g')
        .data(this.matrix)
        .enter()
        .append('g')
        // eslint-disable-next-line prefer-template
        .attr(
          'transform',
          (d) => `translate(${
            d.x * cellWidth
          } ${
            chartHeight - (d.y + 1) * cellHeight
          })`,
        );

      const backgrounds = cells
        .append('rect')
        .attr('x', (d) => 8)
        .attr('y', (d) => 8)
        //.attr('x', (d) => d.x * cellWidth)
        //.attr('y', (d) => chartHeight - (d.y + 1) * cellHeight)
        .attr('width', cellWidth - 16)
        .attr('height', cellHeight - 16)
        .style('fill', 'none')
        .style('stroke-width', 3)
        .style('stroke', (d) => {
          if (d.factorMult < 4) return '#0caa0c';
          if (d.factorMult < 7) return '#bfbfbf';
          if (d.factorMult < 15) return '#f99736';
          return '#fc0042';
        });

      // eslint-disable-next-line prefer-template
      backgrounds
        .append('svg:title')
        .text((d) => `${d.likelihoodLabel} / ${d.impactLabel}`);
      /*
      const riskNodes = cells
        .selectAll('circle')
        .data((d) => {
          const nodesForCell = this.risks.filter(
            (r) => r.impact === d.impactValue && r.likelihood === d.likelihoodValue,
          );
          nodesForCell.forEach((n) => {
            n.x = cellWidth / 2;
            n.y = cellHeight / 2;
          });
          const simulation = d3
            .forceSimulation(nodesForCell)
            .force('charge', d3.forceCollide().radius(radius))
            //.force('radial', d3.forceRadial(Math.min(cellWidth, cellHeight) / 2 - radius, cellWidth / 2, cellHeight / 2))
            .force(
              'center',
              d3.forceCenter(cellWidth / 2, cellHeight / 2).strength(0.1),
            )
            //.force('charge', d3.forceCollide().radius(14))
            .on('tick', () => {
              riskNodes
                .attr('cx', (d2) => {
                  return (d2.x = Math.max(
                    15 + radius,
                    Math.min(cellWidth - 15 - radius, d2.x),
                  ));
                })
                .attr('cy', (d2) => {
                  return (d2.y = Math.max(
                    15 + radius,
                    Math.min(cellHeight - 15 - radius, d2.y),
                  ));
                });
              //.attr('cx', (d2) => { return d2.x; })
              //.attr('cy', (d2) => { return d2.y; });
            })
            .stop();
          console.log(simulation);
          //for (let i = 0; i < 600; ++i) simulation.tick();
          //simulation.restart();

          return nodesForCell;
        })
        .enter()
        .append('circle')
        .attr('cx', cellWidth / 2)
        .attr('cy', cellHeight / 2)
        .attr('r', radius)
        .style('fill', 'white')
        .style('stroke', 'black');

      riskNodes.style('fill', (d) => {
        return 'white';
      }); */
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.updateChart);
      this.updateChart();
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.updateChart);
  },
};
</script>

<style lang="scss" scoped>

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-actions {
  display: flex;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .table {
    .p-datatable {
      flex-grow: 1;
    }
  }
  .chart {
    align-self: center;
    margin-top: 2rem;
    flex-grow: 1;
    width: 30rem;
    height: 30rem;
    //width: min-content;
    //height: min-content;
    display: flex;
    align-items: stretch;
    svg {
      flex-grow: 1;
      //border: thin solid yellow;
    }
  }
}
</style>
