<template>
  <div class="fields">
    <div class="property slider">
      <div class="legend">Inherent impact</div>
      <div>{{ label }} ({{ inherentImpact }})</div>
      <Slider v-model="impactPercentage" :step="1" :min="0" :max="100" @change="$emit('propertyChanged', { 'propertyName': 'inherentImpact', 'propertyValue': inherentImpact})"/>
    </div>
    <!--
    <div class="property">
      <div class="legend">Estimated financial impact</div>
      <div
        class="value p-inputtext"
        contenteditable
        @blur="$emit('propertyChanged', { 'propertyName': 'financialImpact', 'propertyValue': $event.target.innerText})"
        @keydown.enter.prevent="$emit('propertyChanged', { 'propertyName': 'financialImpact', 'propertyValue': $event.target.innerText})"
      >
        {{ element.financialImpact }}
      </div>
    </div>
    -->
  </div>
</template>

<script>
export default {
  emits: ['propertyChanged'],
  props: {
    element: Object,
  },
  computed: {
    label() {
      return this.impacts[this.inherentImpact - 1];
    },
    inherentImpact() {
      return Math.min(5, Math.floor(this.impactPercentage / 20) + 1);
    },
  },
  data() {
    return {
      impactPercentage: (((this.element.inherentImpact || 1) - 1) / 5) * 100,
      impacts: ['neglectable', 'minor', 'moderate', 'critical', 'catastrophic'],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
