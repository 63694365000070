import { createStore } from 'vuex';
import assess from './assess';
import butterfly from './butterfly';
import navigation from './navigation';
import smartsheet from './smartsheet';

const store = createStore({
  modules: {
    assess,
    butterfly,
    navigation,
    smartsheet,
  },
});

store.dispatch('assess/init');
store.dispatch('navigation/init');
store.dispatch('butterfly/init');

export default store;
