import * as axios from 'axios';

const module = {
  namespaced: true,
  state: {
    accessToken: undefined,
    refreshToken: undefined,
    expires: undefined,
    sheets: [],
    currentSheet: undefined,
  },
  mutations: {
    UPDATE_TOKENS(state, data) {
      state.accessToken = data.access_token;
      state.refreshToken = data.refresh_token;
    },
    UPDATE_SHEETS(state, data) {
      state.sheets = data;
    },
  },
  actions: {
    exchangeCodeForTokens(context, code) {
      const clientId = context.rootState.assess.config['smartsheet.oauth.client.id'];
      return axios.post(`https://smartsheet.avalia.io/api/authorized?clientId=${clientId}&code=${code}`)
        .then((response) => {
          context.commit('UPDATE_TOKENS', {
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token,
          });
        })
        .catch((error) => {
          context.commit('UPDATE_TOKENS', {
            access_token: undefined,
            refresh_token: undefined,
          });
        });
    },
    fetchSheets(context) {
      return axios.get(`https://smartsheet.avalia.io/api/sheets?accessToken=${context.state.accessToken}`).then((response) => {
        // console.log('got sheets');
        context.commit('UPDATE_SHEETS', response.data.sheets.data);
      });
    },
    fetchSheet(context, id) {
      return axios.get(`https://smartsheet.avalia.io/api/sheets/${id}?accessToken=${context.state.accessToken}`).then((response) => {
        // context.commit('UPDATE_CURRENT_SHEET', response.data);
        return response.data.sheet;
      });
    },
  },
};

export default module;
