<template>
  <div class="login">
    <div>{{ assess.currentUser.email }}</div>
    <div
      id="google-signin-button"
    ></div>
  </div>
</template>

<script>
import * as axios from 'axios';

export default {
  emits: ['logged-in'],
  data() {
    return {
      googleSignInLibrary: null,
    };
  },
  computed: {
    assess() {
      return this.$store.state.assess;
    },
  },
  methods: {
    onSignIn(credentialResponse) {
      const googleToken = credentialResponse.credential;
      axios
        .post(`${process.env.VUE_APP_ASSESS_API_URL}/auth/googleToAvaliaJWT`, {
          googleToken,
        })
        .then((response) => {
          // console.log(response);
          this.errorMessage = '';
          this.$store.dispatch('assess/login', {
            googleToken,
            assessJwtToken: response.data.token,
            credentialResponse,
          });
          this.$emit('logged-in');
          this.$store.dispatch('assess/fetchProjectTags');
          this.$store.dispatch('assess/fetchPlaybooks', {});
        })
        .catch((error) => {
          const errorMessage = `Login failed : ${error}`;
          console.log(errorMessage);
          // notify.notifyError(errorMessage, this);
        });
    },
    setupGoogleSignIn() {
      window.google.accounts.id.initialize({
      // Get core config like axios.post(`${process.env.VUE_APP_ASSESS_API_URL}/config/core` or in store?
        client_id: '965890852823-irt0g8uk2f9ckvcha1fqbh7oe5pmtekc.apps.googleusercontent.com',
        callback: this.onSignIn,
      });
      // If One Tap prompt is required
      //window.google.accounts.id.prompt();
      window.google.accounts.id.renderButton(
        document.getElementById('google-signin-button'),
        {
          theme: 'filled_black', size: 'large', locale: 'en_CH', shape: 'pill',
        },
      );
    },
  },
  components: {},
  mounted() {
    console.log('Login component mounted');
    this.googleSignInLibrary = document.getElementById('google-signin-library');

    if (window.google) {
      this.setupGoogleSignIn();
    } else {
      this.googleSignInLibrary.addEventListener('load', this.setupGoogleSignIn);
    }
    if (this.assess.currentUser.credentials) {
      this.onSignIn(this.assess.currentUser.credentials);
    }
  },
  unmounted() {
    this.googleSignInLibrary.removeEventListener('load', this.setupGoogleSignIn);
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  div {
    margin-right: 1rem;
  }
}
</style>
