<template>
  <div :style="cssVars" class="activity-group">
    <div v-if="level !== -1"
    class="group-title"
    :class="{ 'drop-target': dragging }"
    draggable = "true"
    @dragstart='startCategoryDrag($event, container)'
    @dragend="endCategoryDrag($event, container)"
    @click="selectCategory(container)"
    @drop="onDrop($event, 'into-container', { container: container })"
    @dragover="onDragOverCategory($event, container);"
    @dragenter="onDragEnterCategory($event, container)"
    @dragleave="onDragLeave($event, container)">
      <!-- <div v-if="level !== 0" @click="showActivityCards = !showActivityCards" class="group-title"> -->
      <!-- <i class="pi" :class="{ 'pi-chevron-down': showActivityCards, 'pi-chevron-up': !showActivityCards }"></i> -->
      <span v-if="displayNumbering && level !== 0">{{ container.autoNumbering?.join('.') }}. </span>{{ container.title }}
    </div>
    <!--
    <Button label="Add category" @click="addCategory" icon="pi pi-plus" class="p-button"/>
    <Button label="Add activity" icon="pi pi-plus" class="p-button"/>
    -->
    <!-- <transition name="slide-fade"> -->
      <div v-if="showActivityCards" class="activities">
          <div class="card-with-dropzones"
            v-for="(activity, index) in activities"
            :key="activity.id"
          >

            <div
              :class="{ 'drop-target': dragging && dragging.draggedObjectType === 'activity', 'drop-target-left': true }"
              @dragover="onDragOverActivity($event, container)"
              @dragenter="onDragEnterActivity($event, container)"
              @dragleave="onDragLeave($event, container)"
              @drop="onDrop($event, 'before-activity', {container: container, activity: activity, index: index})"
            />

            <div
              @click="selectActivity(activity)"
              draggable="true"
              @dragstart='startActivityDrag($event, activity)'
              @dragend="endActivityDrag($event, activity)"
              :class="{
                activity: true,
                completed: activity.status === 'Completed',
                lateToStart: activity.lateToStart,
                lateToFinish: activity.lateToFinish,
              }">
              <div class="row">
                <div class="title">
                  {{ activity.title }}
                </div>
                <div class="activityCode">
                  {{ activity.activityCode }}
                </div>
              </div>
              <div class="icons">
                <div class="icon warning">
                  <i
                    v-if="activity.atRisk"
                    class="pi pi-flag"
                  ></i>
                </div>
                <div class="icon">
                  <i
                    v-if="activity.activityType === 'COORDINATED'"
                    class="pi pi-users"
                  ></i>
                </div>
                <div
                  :class="{
                    icon: true,
                    completed: activity.status === 'Completed',
                    lateToStart: activity.lateToStart,
                    lateToFinish: activity.lateToFinish,
                  }"
                >
                  <i
                    v-if="activity.status === 'In Progress'"
                    class="pi pi-spinner"
                  ></i>
                  <i
                    v-if="activity.status === 'Completed'"
                    class="pi pi-check"
                  ></i>
                  <i
                    v-if="!activity.status || activity.status === 'Not Started'"
                    class="pi pi-calendar"
                  ></i>
                </div>
              </div>
            </div>

            <div
              :class="{ 'drop-target': dragging && dragging.draggedObjectType === 'activity', 'drop-target-right': true }"
              @dragover="onDragOverActivity($event, container)"
              @dragenter="onDragEnterActivity($event, container)"
              @dragleave="onDragLeave($event, container)"
              @drop="onDrop($event, 'after-activity', {container: container, activity: activity, index: index})"
            />

          </div>

          <!-- the placeholders are used to left-align the cards on the last row; see https://medium.com/developedbyjohn/equal-width-flex-items-a5ba1bfacb77 -->
            <div
              class="drop-target-right"
              @dragenter="onDragEnter($event, container)"
              @dragleave="onDragLeave($event, container)"
              @dragover.prevent
              @dragenter.prevent/>
          <div class="placeholder"></div>
            <div
              class="drop-target-right"
              @dragenter="onDragEnter($event, container)"
              @dragleave="onDragLeave($event, container)"
              @dragover.prevent
              @dragenter.prevent/>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
          <!-- the placeholders are used to left-align the cards on the last row; see https://medium.com/developedbyjohn/equal-width-flex-items-a5ba1bfacb77 -->
    </div>
    <!-- </transition> -->
    <PlaybookContainer
      v-for="(category, index) in container.categories"
      :key="category.id"
      :level="level + 1"
      :numbering="numbering + (index + 1) + '.'"
      @select-activity="selectActivity"
      @select-category="selectCategory"
      :playbook="playbook"
      :container="category"
    ></PlaybookContainer>
  </div>
</template>

<script>
export default {
  name: 'PlaybookContainer',
  emits: ['select-activity', 'select-category', 'update:showActivities'],
  props: {
    playbook: Object,
    container: Object,
    numbering: String,
    showActivities: Object,
    level: {
      type: Number,
      default: 0,
    },
  },
  components: {
  },
  data() {
    return {
      counter: 1,
      showActivityCards: true,
      cssVars: {
        '--level': this.level,
      },
      dropTargetId: undefined,
    };
  },
  computed: {
    activities() {
      return this.container.activities;
    },
    displayNumbering() {
      return this.$store.state.navigation.displayNumbering;
    },
    dragging: {
      set(value) {
        this.$store.commit('navigation/SET_DRAGGING', value);
      },
      get() {
        return this.$store.state.navigation.dragging;
      },
    },
  },
  methods: {
    selectActivity(activity) {
      this.$emit('select-activity', activity);
    },
    selectCategory(category) {
      this.$emit('select-category', category);
    },
    onDragOverActivity(event, target) {
      // we only allow to drop an activity before/after an activity, we do not allow dropping a category on an activity
      if (this.dragging.draggedObjectType === 'activity') {
        event.preventDefault();
      }
    },
    onDragEnterActivity(event, target) {
      if (this.dragging.draggedObjectType === 'activity') {
        event.target.classList.add('drop-active');
        event.preventDefault();
      }
    },
    onDragOverCategory(event, target) {
      event.preventDefault();
    },
    onDragEnterCategory(event, target) {
      event.target.classList.add('drop-active');
      event.preventDefault();
    },
    onDragLeave(event, target) {
      event.target.classList.remove('drop-active');
    },
    onDrop(event, targetType, target) {
      let index = 0;
      switch (targetType) {
        case 'into-container':
          index = 0;
          break;
        case 'before-activity':
          index = target.index;
          break;
        case 'after-activity':
          index = target.index + 1;
          break;
        default:
          index = 0;
      }
      if (this.dragging.draggedObjectType === 'activity') {
        const command = {
          playbook: this.playbook,
          data: {
            activityId: this.dragging.draggedObject.id,
            destinationContainerId: target.container.id,
            index,
          },
        };
        this.$store.dispatch('assess/moveActivity', command);
      } else if (this.dragging.draggedObjectType === 'category') {
        const command = {
          playbook: this.playbook,
          data: {
            categoryId: this.dragging.draggedObject.id,
            destinationContainerId: target.container.id,
            index,
          },
        };
        this.$store.dispatch('assess/moveCategory', command);
      }
    },
    startActivityDrag(event, item) {
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.effectAllowed = 'move';
      this.dragging = {
        draggedObjectType: 'activity',
        draggedObject: item,
      };
    },
    endActivityDrag(event) {
      this.dragging = false;
    },
    startCategoryDrag(event, item) {
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.effectAllowed = 'move';
      this.dragging = {
        draggedObjectType: 'category',
        draggedObject: item,
      };
    },
    endCategoryDrag(event) {
      this.dragging = false;
    },
    addCategory() {
      this.$store.dispatch('assess/createCategory', {
        playbook: this.playbook,
        data: {
          title: `Untitled category ${this.counter}`,
        },
        containerId: this.container.id,
      });
      this.counter++;
    },
  },
};
</script>

<style lang="scss" scoped>

.drop-target {
  // width: 1rem;
  // margin-left: 0.5rem;
  // margin-right: 0.5rem;
  background-color: var(--surface-c);
  // border: thin dotted transparent;
}

.drop-active {
  // border: thin dotted var(--primary-color);
  color: var(--surface-a) !important;
  background-color: var(--primary-color);
  //padding: 1rem;
}

.card-with-dropzones {
  display: flex;
  flex-grow: 1;
  margin-left: -1rem;
  // margin-right: -1rem;
  margin-bottom: 1rem;
}

.card-with-dropzones {
  .drop-target-left {
    display: flex;
    width: 1rem;
    position: relative;
    left: 1rem;
    border-top-left-radius: 0.4rem;
    z-index: 999;
  }
  .drop-target-right {
    display: flex;
    width: 1rem;
    position: relative;
    right: 1rem;
    border-bottom-right-radius: 0.4rem;
    z-index: 999;
  }
}

.slide-fade-enter-active {
  transition: all 0.0s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.0s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-0px);
  opacity: 0;
}

.activity-group {
  /* padding-left: calc((var(--level) - 1) * 2rem); */
  display: flex;
  flex-direction: column;

  .group-title {
    cursor: pointer;
    font-size: 1.8rem;
    color: var(--text-color);
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;

    i {
      margin-right: 1rem;
    }
  }

  .activities {
    margin-right: -1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    //column-gap: 1rem;
    //row-gap: 1rem;

    .activity:hover {
      border: 2px solid var(--text-color);
    }

    .activity {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      flex-grow: 1;
      z-index: 1;
      font-size: 1.2rem;
      width: 16rem;
      min-height: 12rem;
      text-align: left;
      border: 2px solid var(--surface-c);

      // background-color: rgba(0, 0, 0, 0.5);
      background-color: var(--surface-a);
      padding: 1rem;
      border-top-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;

      .row {
        display: flex;
        justify-content: space-between;
        .title {
          text-align: left;
        }
        .activityCode {
          margin-left: 0.4rem;
          color: var(--primary-color);
          font-weight: bold;
          white-space: nowrap;
        }
      }

      .icons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .icon {
          width: 2rem;
          height: 2rem;
          color: var(--surface-c);
          /* border: thin solid rgba(255, 166, 0, 0.404); */
          align-self: flex-end;
          display: flex;
          align-items: center;
          justify-content: center;
          .pi {
            font-size: 1.7rem;
          }
        }
        .warning {
          color: var(--danger-color);
        }
        .completed {
          color: var(--primary-color);
        }
        .lateToStart {
          color: var(--warning-color);
        }
        .lateToFinish {
          color: var(--warning-color);
        }
      }
    }

    .placeholder {
      flex-grow: 1;
      width: 17rem;
      border: none;
    }
  }
}
</style>
