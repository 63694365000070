<template>
  <div class="table">
    <playbook-view-table-category @select-activity="selectActivity" @select-category="selectCategory" :container="container" />
  </div>
</template>

<script>
import PlaybookViewTableCategory from './PlaybookViewTableCategory.vue';

export default {
  emits: ['select-activity', 'select-category'],
  components: {
    PlaybookViewTableCategory,
  },
  props: {
    container: Object,
  },
  computed: {
    playbook() {
      return this.$store.state.assess.playbook;
    },
  },
  methods: {
    selectActivity(activity) {
      this.$emit('select-activity', activity);
    },
    selectCategory(category) {
      this.$emit('select-category', category);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
}
</style>
