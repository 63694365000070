<template>
  <div class="events">
    <h2>Events</h2>
    <div class="event" v-for="event in events" :key="event.id">
      <div class="time">
        {{ event.date }}
      </div>
      <div class="author">
        {{ event.author.firstName }} {{ event.author.lastName }}
      </div>
      <div class="verb">
        updated
      </div>
      <div class="targetType">
        {{ event.targetType }}
      </div>
      <div @click="selectActivity($store.getters['assess/getActivityById'](playbook, event.targetId))" class="targetName">
        {{ $store.getters['assess/getActivityById'](playbook, event.targetId).title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    container: Object,
  },
  components: {
  },
  computed: {
    playbook() {
      return this.$store.state.assess.playbook;
    },
    events() {
      return this.$store.state.assess.events;
    },
    requestsInFlight() {
      return this.$store.state.assess.numberOfInflightRequests;
    },
  },
  methods: {
    selectActivity(activity) {
      this.$emit('select-activity', activity);
    },
    selectCategory(category) {
      this.$emit('select-category', category);
    },
  },
};
</script>

<style lang="scss" scoped>

.events {
  text-align: left;

  .event {
    display: flex;
    margin-bottom: 0.3rem;
    div {
      margin-right: 1rem;
    }
    .targetName {
      color: var(--primary-color);
      font-weight: 700;
    }
  }
}
    .loading-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      .pi {
        color: var(--surface-c);
        /* color: white; */
        font-size: 18rem;
      }
    }
</style>
