<template>
  <Sidebar v-model:visible="visible" @hide="hideSidebar" position="top" class="p-sidebar-lg avalia-sidebar-top p-input-filled">
    <div class="p-grid">
      <div class="p-col">
        <h3>Theme</h3>
        <Dropdown @change="changeTheme" v-model="currentTheme" :options="themes" optionLabel="name" placeholder="Select a theme"></Dropdown>
      </div>
      <div class="p-col">
        <h3>Display numbering</h3>
        <SelectButton v-model="displayNumbering" :options="optionsOnOff" optionLabel="name" optionValue="value" />
      </div>
      <div v-show="false" class="p-col">
        <h3>Smartsheet integration</h3>
        <Button v-show="!smartsheet.accessToken" @click="connectWithSmartsheets" label="Authorize Smartsheet" icon="pi pi-key" class="p-button-outlined" />
      </div>
    </div>
  </Sidebar>
</template>

<script>
import axios from 'axios';

export default {
  emits: ['update:showSettingsSidebar'],
  props: {
    showSettingsSidebar: Boolean,
  },
  data() {
    return {
      visible: false,
      themes: [
        { name: 'Dark Blue', url: '/themes/avalia-dark-blue' },
        { name: 'Dark Purple', url: '/themes/avalia-dark-purple' },
        { name: 'Light Purple', url: '/themes/avalia-light-purple' },
      ],
      currentTheme: undefined,
      optionsOnOff: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
    };
  },
  computed: {
    displayNumbering: {
      get() {
        return this.$store.state.navigation.displayNumbering;
      },
      set(value) {
        this.$store.commit('navigation/SET_DISPLAY_NUMBERING', value);
      },
    },
    config() {
      return this.$store.state.assess.config;
    },
    smartsheet() {
      return this.$store.state.smartsheet;
    },
  },
  methods: {
    hideSidebar() {
      this.$emit('update:showSettingsSidebar', false);
    },
    changeTheme(event) {
      this.$store.commit('navigation/SET_CURRENT_THEME', event.value);
    },
    connectWithSmartsheets() {
      const clientId = this.config['smartsheet.oauth.client.id'];
      axios.get(`https://smartsheet.avalia.io/api/auth?clientId=${clientId}`).then((response) => {
        const thisUrl = this.$route.path;
        window.location = `${response.data.authorizationUri}&state=${thisUrl}`;
      });
    },
  },
};
</script>
