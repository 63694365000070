<template>
  <a @click="downloadHtml">Download html</a>
  <div class="card">
    <div class="loading-spinner">
      <i v-if="requestsInFlight" class="pi pi-spin pi-spinner" />
    </div>
  </div>
  <div class="table">
    <playbook-view-document-category @select-activity="selectActivity" @select-category="selectCategory" :container="container" />
  </div>
</template>

<script>
import PlaybookViewDocumentCategory from './PlaybookViewDocumentCategory.vue';

export default {
  emits: ['select-activity', 'select-category'],
  components: {
    PlaybookViewDocumentCategory,
  },
  props: {
    container: Object,
  },
  computed: {
    playbook() {
      return this.$store.state.assess.playbook;
    },
    requestsInFlight() {
      return this.$store.state.assess.numberOfInflightRequests;
    },
  },
  methods: {
    selectActivity(activity) {
      this.$emit('select-activity', activity);
    },
    selectCategory(category) {
      this.$emit('select-category', category);
    },
    appendHtmlForContainer(html, container) {
      let fragment = '';
      container.activities.forEach((a) => {
        fragment += `<div class="activity"><div class="title">${a.title}</div><div class="summary">${a.summary}</div></div>\n`;
      });
      container.categories.forEach((c) => {
        fragment += `<div class="category"><div class="title">${c.title}</div>\n`;
        fragment += this.appendHtmlForContainer('', c);
        fragment += '</div>\n';
      });
      return html + fragment;
    },
    downloadHtml() {
      const htmlPrefix = '<html><head><style>.category { margin-left: 1rem; color: blue;} .activity { margin-left: 1rem; color: green;}</style></head><body>';
      const htmlSuffix = '</body></html>';
      const html = this.appendHtmlForContainer('', this.container);
      const anchor = document.createElement('a');
      anchor.href = `data:text/html;charset=utf-8,${encodeURIComponent(htmlPrefix + html + htmlSuffix)}`;
      anchor.target = '_blank';
      anchor.download = 'report.html';
      anchor.click();
    },
  },
};
</script>

<style lang="scss" scoped>
    .loading-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      .pi {
        color: var(--surface-c);
        /* color: white; */
        font-size: 18rem;
      }
    }
</style>
