<template>
  <Sidebar
    v-model:visible="visible"
    @hide="hideSlidebar"
    position="right"
    class="p-sidebar-lg avalia-sidebar avalia-sidebar-right p-input-filled"
  >
    <div class="card">
      <div class="loading-spinner">
        <i v-if="requestsInFlight" class="pi pi-spin pi-spinner" />
      </div>
      <div class="title-bar">
        <div
          contenteditable
          @blur="propertyChanged('label', $event)"
          @keydown.enter.prevent="propertyChanged('label', $event)"
          class="title"
        >
          {{ selectedElement.label }}
        </div>
      </div>
      <div class="fields">
        <div class="property">
          <div class="legend">Summary</div>
          <div
            class="value p-inputtext"
            contenteditable
            @blur="propertyChanged('summary', $event)"
          >
            {{ selectedElement.summary }}
          </div>
        </div>
      </div>
      <div class="elementPanel">
        <component :is="elementPanelComponent" :situation='situation' :element='selectedElement' @propertyChanged="propertyChangedInPanel"></component>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import PanelEvent from './PanelEvent.vue';
import PanelOutcome from './PanelOutcome.vue';
import PanelProactiveAction from './PanelProactiveAction.vue';
import PanelReactiveAction from './PanelReactiveAction.vue';
import PanelSeed from './PanelSeed.vue';

export default {
  components: {
    PanelEvent,
    PanelOutcome,
    PanelProactiveAction,
    PanelReactiveAction,
    PanelSeed,
  },
  emits: ['update:showElementSidebar', 'elementUpdated'],
  props: {
    situation: Object,
    showElementSidebar: Boolean,
    selectedElement: Object,
    elementType: String,
  },
  computed: {
    requestsInFlight() {
      return this.$store.state.butterfly.numberOfInflightRequests;
    },
    elementPanelComponent() {
      return `Panel${this.elementType}`;
    },
  },
  watch: {
    selectedElement(newVal) {
    },
    showElementSidebar(newVal) {
      this.visible = this.showElementSidebar;
    },
    selectedScope(newVal) {
    },
  },
  data() {
    return {
      visible: false,
      selectedScope: null,
    };
  },
  methods: {
    hideSlidebar() {
      this.$emit('update:showElementSidebar', false);
    },
    propertyChanged(name, event) {
      const newValue = event.target.innerText;
      this.$store.dispatch('butterfly/updateElementProperty', {
        situation: this.situation, element: this.selectedElement, propertyName: name, propertyValue: newValue,
      }).then(() => {
        this.$emit('elementUpdated', this.selectedElement);
      });
    },
    propertyChangedInPanel({ propertyName, propertyValue }) {
      this.$store.dispatch('butterfly/updateElementProperty', {
        situation: this.situation, element: this.selectedElement, propertyName, propertyValue,
      }).then(() => {
        this.$emit('elementUpdated', this.selectedElement);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.elementPanel {
  margin-top: 2rem;
}
</style>
