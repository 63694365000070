/* eslint-disable no-param-reassign */
import * as axios from 'axios';
import jwtDecode from 'jwt-decode';
import { toDate } from 'date-fns-tz';
import Bottleneck from 'bottleneck';

const api = axios.create({
  baseURL: process.env.VUE_APP_ASSESS_API_URL,
  withCredentials: true,
});

/*
 * To avoid concurrency problems, we issue commands to Avalia Assess in sequence
 */
const sequencer = new Bottleneck({
  maxConcurrent: 1,
});

function addLevel(container, level) {
  container.level = level;
  container.categories.forEach((category) => {
    addLevel(category, level + 1);
  });
  container.activities.forEach((activity) => {
    activity.level = level + 1;
  });
}
/*
 * We send our commands to Avalia Playbook via Avalia Assess (so that we can enforce access rights).
 * Every playbook is linked to a Playbook Task on the assess side
 */
function wrapPlaybookCommandIntoAssessCommand(playbookCommand) {
  return {
    action: 'WORK',
    taskWorkDTO: {
      command: playbookCommand,
      type: 'playbook task',
    },
  };
}

function augmentActivity(activity, numbering) {
  activity.numbering = numbering;
  if (activity.status === 'Complete') {
    activity.status = 'Completed';
  }
  activity.atRisk = activity?.isFlagged || activity?.additionalProperties['At Risk']?.value;
  delete activity?.additionalProperties['At Risk'];
  activity.lateToStart = (!activity.status || activity.status === '' || activity.status === 'Not Started') && new Date() > toDate(activity.startDate);
  activity.lateToFinish = activity.status !== 'Completed' && new Date() > toDate(activity.dueDate);
  activity.milestones.sort((m1, m2) => {
    if (m1.dueDate < m2.dueDate) {
      return -1;
    }
    if (m1.dueDate > m2.dueDate) {
      return 1;
    }
    return 0;
  });
}

function augmentContainer(container, numbering, parentIds) {
  let counter = 1;
  container.numbering = `C_${numbering}`;
  container.activities.forEach((activity) => {
    augmentActivity(activity, `${numbering}${counter}.`);
    if (!activity.isArchived) counter++;
  });
  container.categories.forEach((category) => {
    augmentContainer(category, `${numbering}${counter}.`);
    if (!container.isArchived) counter++;
  });
}

function augmentPlaybook(playbookToAugment) {
  const numbering = '';
  augmentContainer(playbookToAugment, numbering);
}

const module = {
  namespaced: true,
  state: {
    coreConfig: {},
    currentUser: {},
    projectTags: [],
    playbooks: [],
    playbook: {},
    playbookNotes: [],
    events: [],
    numberOfInflightRequests: 0,
  },
  getters: {
    filteredPlaybook: (state) => (includeArchivedItems, filter) => {
      function visit(container, autoNumbering) {
        container.categories = container.categories?.filter((c) => {
          if (!includeArchivedItems && c.isArchived) {
            return false;
          }
          return true;
        });
        let autoNumberingCounter = 1;
        container.categories?.forEach((c) => {
          c.autoNumbering = [...autoNumbering, autoNumberingCounter++];
        });
        container.activities = container.activities?.filter((a) => {
          if (!includeArchivedItems && a.isArchived) {
            return false;
          }
          if (filter && filter?.trim() !== '' && !a.title.concat(a.owner).concat(a.assignee).concat(a.activityType).toUpperCase()
            .includes(filter.toUpperCase())) {
            return false;
          }
          return true;
        });
        container.categories?.forEach((c) => visit(c, c.autoNumbering));
      }
      const playbookClone = JSON.parse(JSON.stringify(state.playbook));
      const autoNumbering = [];
      playbookClone.autoNumbering = autoNumbering;
      visit(playbookClone, autoNumbering);
      return playbookClone;
    },
    getActivityById: (state) => (playbook, id) => {
      function findInContainer(container) {
        let found = container.activities?.find((a) => a.id === id);
        if (found) return found;
        for (let i = 0; i < container.categories.length; i++) {
          found = findInContainer(container.categories[i]);
          if (found) return found;
        }
        return undefined;
      }
      return findInContainer(playbook);
    },
    getCategoryById: (state) => (playbook, id) => {
      function findInContainer(container) {
        let found = container.categories?.find((c) => c.id === id);
        if (found) return found;
        for (let i = 0; i < container.categories.length; i++) {
          found = findInContainer(container.categories[i]);
          if (found) return found;
        }
        return undefined;
      }
      return findInContainer(playbook);
    },
    activities: (state) => (playbook) => {
      const result = [];
      function addActivitiesFromContainer(container) {
        container.activities.forEach((a) => result.push(a));
        container.categories.forEach((c) => addActivitiesFromContainer(c));
      }
      addActivitiesFromContainer(playbook);
      return result;
    },
  },
  mutations: {
    UPDATE_CORE_CONFIG(state, data) {
      state.config = data;
    },
    UPDATE_CURRENT_USER(state, data) {
      state.currentUser = data;
    },
    UPDATE_PLAYBOOKS(state, data) {
      state.playbooks = data;
    },
    UPDATE_CURRENT_PLAYBOOK(state, data) {
      state.playbook = data;
      addLevel(data, 0);
    },
    UPDATE_PROJECT_TAGS: (state, tags) => {
      state.projectTags = tags;
    },
    UPDATE_EVENTS: (state, events) => {
      state.events = events;
    },
    INC_NUMBER_OF_INFLIGHT_REQUESTS(state, delta) {
      state.numberOfInflightRequests += delta;
    },
  },
  actions: {
    init(context) {
      api.interceptors.request.use(
        (config) => {
          context.commit('INC_NUMBER_OF_INFLIGHT_REQUESTS', 1);
          return config;
        },
        (error) => {
          setTimeout(() => context.commit('INC_NUMBER_OF_INFLIGHT_REQUESTS', -1), 50);
          return Promise.reject(error);
        },
      );
      api.interceptors.response.use(
        (response) => {
          setTimeout(() => context.commit('INC_NUMBER_OF_INFLIGHT_REQUESTS', -1), 50);
          return response;
        },
        (error) => {
          setTimeout(() => context.commit('INC_NUMBER_OF_INFLIGHT_REQUESTS', -1), 50);
          return Promise.reject(error);
        },
      );

      return api.get('/config/core').then((response) => {
        context.commit('UPDATE_CORE_CONFIG', response.data);
      });
    },
    login(context, { googleToken, assessJwtToken, credentialResponse }) {
      const googleUser = jwtDecode(googleToken);
      //console.log('user in login', googleUser, googleUser.email, googleUser.hd);
      context.commit('UPDATE_CURRENT_USER', {
        email: googleUser.email,
        domain: googleUser.hd,
        profile: googleUser,
        credentials: credentialResponse,
      });
      const header = `Bearer ${assessJwtToken}`;
      api.defaults.headers.common.Authorization = header;
    },
    fetchPlaybooks(context, { filterByName, filterByTags }) {
      let url = '/projects?caseType=Avalia%20Playbook';
      if (filterByName && filterByName.trim() !== '') {
        url += `&name=${filterByName}`;
      }
      if (filterByTags && filterByTags.length > 0) {
        url += `&tags=${filterByTags.map((t) => t.name.toLowerCase()).join(',')}`;
      }
      //return api.get(`/projects?caseType=Avalia%20Playbook&users=${context.state.currentUser.email}`).then((response) => {
      return api.get(url).then((response) => {
        context.commit('UPDATE_PLAYBOOKS', response.data.content);
      });
    },
    fetchProjectTags(context) {
      const url = '/tags?type=project tag';
      return api
        .get(url)
        .then((response) => {
          // eslint-disable-next-line no-underscore-dangle
          const tags = response.data;
          context.commit('UPDATE_PROJECT_TAGS', tags);
        })
        .catch((error) => {
          console.log(error);
          // const errorMessage = `Could not Fetch Project's Tags : ${error.response.data.message || ''}`;
          // notify.notifyError(errorMessage, vm);
        });
    },
    fetchPlaybook(context, projectId) {
      return api
        .get(`/projects/${projectId}`)
        .then((response) => {
          return response.data.tasks.find((task) => task.type === 'playbook task');
        })
        .then((task) => {
          return api.get(`/tasks/${task.id}/playbooks`).then((response) => {
            const playbook = { ...response.data, assessTaskId: task.id };
            augmentPlaybook(playbook);
            context.commit('UPDATE_CURRENT_PLAYBOOK', playbook);
            return playbook;
          });
        });
    },
    fetchPlaybookByAssessTaskId(context, assessTaskId) {
      return api.get(`/tasks/${assessTaskId}/playbooks`).then((response) => {
        const playbook = { ...response.data, assessTaskId };
        augmentPlaybook(playbook);
        context.commit('UPDATE_CURRENT_PLAYBOOK', playbook);
        return playbook;
      });
    },
    fetchEventsByAssessTaskId(context, { assessTaskId, playbookId }) {
      const playbookCommand = {
        playbookId,
        type: 'GetEventCustomCommand',
        pageable: {
          size: 20,
          page: 0,
        },
        groups: [
          //'date',
          //'targetName',
        ],
        filters: [
          {
            type: 'StringEqualsEventFilter',
            key: 'targetType',
            value: 'activity',
          },
        ],
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);

      return api.post(`/tasks/${assessTaskId}`, assessCommand).then((response) => {
        const events = response.data.page.content;
        context.commit('UPDATE_EVENTS', events);
        return events;
      });
    },
    updateActivity(context, propertyChange) {
      const playbookCommand = {
        ...propertyChange,
        type: 'UpdateActivityCommand',
      };
      delete playbookCommand.assessTaskId;
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);

      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${propertyChange.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', propertyChange.assessTaskId);
        });
    },
    toggleActivityFlag(context, propertyChange) {
      const playbookCommand = {
        playbookId: propertyChange.playbookId,
        activityId: propertyChange.activity.id,
      };
      if (propertyChange.activity.isFlagged) {
        playbookCommand.type = 'UnflagActivityCommand';
      } else {
        playbookCommand.type = 'FlagActivityCommand';
      }
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);

      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${propertyChange.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', propertyChange.assessTaskId);
        });
    },
    addConditionOfCompletion(context, conditionOfCompletion) {
      const playbookCommand = {
        ...conditionOfCompletion,
        type: 'AddConditionCommand',
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${conditionOfCompletion.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', conditionOfCompletion.assessTaskId);
        });
    },
    archiveConditionOfCompletion(context, change) {
      const playbookCommand = {
        playbookId: change.playbookId,
        activityId: change.activityId,
        conditionId: change.conditionId,
        type: 'ArchiveConditionCommand',
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${change.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', change.assessTaskId);
        });
    },
    updateConditionOfCompletion(context, change) {
      const playbookCommand = {
        playbookId: change.playbookId,
        activityId: change.activityId,
        conditionId: change.conditionId,
        condition: change.condition,
        type: 'UpdateConditionCommand',
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${change.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', change.assessTaskId);
        });
    },
    toggleConditionOfCompletion(context, propertyChange) {
      const playbookCommand = {
        playbookId: propertyChange.playbookId,
        activityId: propertyChange.activityId,
        conditionId: propertyChange.condition.id,
      };
      if (propertyChange.condition.isComplete) {
        playbookCommand.type = 'UncompleteConditionCommand';
      } else {
        playbookCommand.type = 'CompleteConditionCommand';
      }
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);

      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${propertyChange.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', propertyChange.assessTaskId);
        });
    },
    addNote(context, change) {
      const playbookCommand = {
        playbookId: change.playbookId,
        activityId: change.activityId,
        text: change.note,
        type: 'AddActivityNoteCommand',
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${change.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', change.assessTaskId);
        });
    },
    // Will currently Archive the note
    deleteNote(context, change) {
      const playbookCommand = {
        playbookId: change.playbookId,
        activityId: change.activityId,
        noteId: change.noteId,
        type: 'ArchiveActivityNoteCommand',
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${change.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', change.assessTaskId);
        });
    },
    updateNote(context, change) {
      const playbookCommand = {
        playbookId: change.playbookId,
        activityId: change.activityId,
        noteId: change.noteId,
        text: change.note,
        type: 'UpdateActivityNoteCommand',
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${change.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', change.assessTaskId);
        });
    },
    addResource(context, change) {
      const playbookCommand = {
        playbookId: change.playbookId,
        activityId: change.activityId,
        ...change.resource,
        type: 'AddResourceCommand',
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${change.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', change.assessTaskId);
        });
    },
    updateResource(context, change) {
      const playbookCommand = {
        playbookId: change.playbookId,
        activityId: change.activityId,
        resourceId: change.resource.id,
        ...change.resource,
        type: 'UpdateResourceCommand',
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${change.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', change.assessTaskId);
        });
    },
    archiveResource(context, change) {
      const playbookCommand = {
        playbookId: change.playbookId,
        activityId: change.activityId,
        resourceId: change.resource.id,
        type: 'ArchiveResourceCommand',
      };
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${change.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', change.assessTaskId);
        });
    },
    createActivity(context, { playbook, data, containerId }) {
      const addActivityCommand = {
        type: 'AddActivityCommand',
        playbookId: playbook._id,
        containerId,
        ...data,
      };
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${playbook.assessTaskId}`, wrapPlaybookCommandIntoAssessCommand(addActivityCommand));
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', playbook.assessTaskId);
        });
    },
    archiveActivity(context, { playbook, activityId }) {
      const archiveActivityCommand = {
        type: 'ArchiveActivityCommand',
        playbookId: playbook._id,
        activityId,
      };
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${playbook.assessTaskId}`, wrapPlaybookCommandIntoAssessCommand(archiveActivityCommand));
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', playbook.assessTaskId);
        });
    },
    unarchiveActivity(context, { playbook, activityId }) {
      const archiveActivityCommand = {
        type: 'UnarchiveActivityCommand',
        playbookId: playbook._id,
        activityId,
      };
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${playbook.assessTaskId}`, wrapPlaybookCommandIntoAssessCommand(archiveActivityCommand));
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', playbook.assessTaskId);
        });
    },
    moveActivity(context, { playbook, data }) {
      const playbookCommand = {
        playbookId: playbook._id,
        ...data,
        type: 'MoveActivityCommand',
      };
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${playbook.assessTaskId}`, wrapPlaybookCommandIntoAssessCommand(playbookCommand));
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', playbook.assessTaskId);
        });
    },
    createCategory(context, { playbook, data, containerId }) {
      const addActivityCategoryCommand = {
        type: 'AddCategoryCommand',
        playbookId: playbook._id,
        containerId,
        title: `${data.title}`,
      };
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${playbook.assessTaskId}`, wrapPlaybookCommandIntoAssessCommand(addActivityCategoryCommand));
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', playbook.assessTaskId);
        });
    },
    updateCategory(context, propertyChange) {
      const playbookCommand = {
        ...propertyChange,
        type: 'UpdateCategoryCommand',
      };
      delete playbookCommand.assessTaskId;
      const assessCommand = wrapPlaybookCommandIntoAssessCommand(playbookCommand);

      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${propertyChange.assessTaskId}`, assessCommand);
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', propertyChange.assessTaskId);
        });
    },
    archiveCategory(context, { playbook, categoryId }) {
      const archiveCategoryCommand = {
        type: 'ArchiveCategoryCommand',
        playbookId: playbook._id,
        categoryId,
      };
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${playbook.assessTaskId}`, wrapPlaybookCommandIntoAssessCommand(archiveCategoryCommand));
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', playbook.assessTaskId);
        });
    },
    unarchiveCategory(context, { playbook, categoryId }) {
      const unarchiveCategoryCommand = {
        type: 'UnarchiveCategoryCommand',
        playbookId: playbook._id,
        categoryId,
      };
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${playbook.assessTaskId}`, wrapPlaybookCommandIntoAssessCommand(unarchiveCategoryCommand));
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', playbook.assessTaskId);
        });
    },
    moveCategory(context, { playbook, data }) {
      const playbookCommand = {
        playbookId: playbook._id,
        ...data,
        type: 'MoveCategoryCommand',
      };
      return sequencer
        .schedule(() => {
          return api.post(`/tasks/${playbook.assessTaskId}`, wrapPlaybookCommandIntoAssessCommand(playbookCommand));
        })
        .then(() => {
          return context.dispatch('fetchPlaybookByAssessTaskId', playbook.assessTaskId);
        });
    },
    createCategoriesAndActivitiesFromSmartsheet(context, { playbook, assessTaskId, smartsheetRootNode }) {},
    overwritePlaybookWithSmartsheet(context, { assessTaskId, playbook, smartsheetRootNode }) {},
  },
};

export default module;
