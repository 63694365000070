<template>
      <div class="fields">
        <div class="property">
          <div class="property slider">
            <div class="legend"><b>Target effectiveness</b>. When fully implemented, this action will reduce the impact by:</div>
            <div>{{ effectivenessWhenFullyImplemented }} %</div>
            <Slider v-model="effectivenessWhenFullyImplemented" :step="1" :min="0" :max="100" @change="$emit('propertyChanged', { 'propertyName': 'effectivenessWhenFullyImplemented', 'propertyValue': effectivenessWhenFullyImplemented})"/>
          </div>
        </div>
        <div class="property">
          <div class="property slider">
            <div class="legend"><b>Current effectiveness</b>. Currently, this action reduces the impact by:</div>
            <div>{{ currentEffectiveness }} %</div>
            <Slider v-model="currentEffectiveness" :step="1" :min="0" :max="effectivenessWhenFullyImplemented" @change="$emit('propertyChanged', { 'propertyName': 'currentEffectiveness', 'propertyValue': currentEffectiveness})"/>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  emits: ['propertyChanged'],
  props: {
    element: Object,
  },
  computed: {
  },
  data() {
    return {
      currentEffectiveness: this.element.currentEffectiveness || 0,
      effectivenessWhenFullyImplemented: this.element.effectivenessWhenFullyImplemented || 0,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
