<template>
  <main-layout>
    <template v-slot:header>
      <div>Risks</div>
    </template>

    <template v-slot:views>
      <i
        @click="$router.push('/playbooks')"
        class="pi pi-home"
        :class="{ current: currentView === 'home' }"
      ></i>
      <i
        @click="$router.push('/risks')"
        class="pi pi-exclamation-triangle"
        :class="{ current: currentView === 'register' }"
      ></i>
    </template>

    <template v-slot:title>
      Risk and opportunities
    </template>

    <template v-slot>
      <div class="view">
        <router-view />
      </div>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      currentView: 'register',
      filterByName: '',
      filterByTags: null,
      tags: null,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss">
  body .panel-class {
    border-radius: 0px;
    background-color: #00567f;
    color: black;
  }
</style>
<style lang="scss" scoped>
.view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // align-items: flex-start;
  // padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

</style>
