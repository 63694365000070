<template>
  <Sidebar
    v-model:visible="visible"
    @hide="hideSlidebar"
    position="right"
    class="p-sidebar-lg avalia-sidebar avalia-sidebar-right p-input-filled"
  >
    <div class="card">
      <div class="loading-spinner">
        <i v-if="requestsInFlight" class="pi pi-spin pi-spinner" />
      </div>
      <div class="title-bar">
        <div
          contenteditable
          @blur="propertyChanged('title', $event)"
          @keydown.enter.prevent="propertyChanged('title', $event)"
          class="title"
        >
          {{ selectedCategory.title }}
        </div>
      </div>
      <!--
      <TreeSelect v-model="selectedScope" :options="treeNodesFromPlaybook" @node-select="onSelectDestination" placeholder="Table of Content">
      </TreeSelect>
      -->
      <div class="fields">
        <div class="property">
          <div class="legend">Summary</div>
          <div
            class="value p-inputtext"
            contenteditable
            @blur="propertyChanged('summary', $event)"
          >
            {{ selectedCategory.summary }}
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
export default {
  emits: ['update:showCategorySidebar', 'categoryUpdated'],
  props: {
    playbook: Object,
    showCategorySidebar: Boolean,
    selectedCategory: Object,
  },
  computed: {
    requestsInFlight() {
      return this.$store.state.assess.numberOfInflightRequests;
    },
    navigation() {
      return this.$store.state.navigation;
    },
    treeNodesFromPlaybook() {
      const that = this;
      function nodeFromContainer(container, containerType) {
        const id = container.id || container._id;

        let icon = 'pi-folder-open';
        switch (containerType) {
          case 'Playbook':
            icon = 'pi-book';
            break;
          default:
            icon = 'pi-folder-open';
        }

        const children = [];
        const node = {
          key: id,
          label: `${that.navigation.displayNumbering ? container.autoNumbering?.join('.') : ''}${container.autoNumbering?.length > 0 ? '. ' : ''} ${container.title}`,
          data: container,
          icon: `pi pi-fw ${icon}`,
          children,
        };
        container.categories?.forEach((category) => {
          children.push(nodeFromContainer(category, 'Category'));
        });

        /*
        container.activities?.forEach((activity) => {
          // eslint-disable-next-line prefer-template
          const label = ['[' + (activity.activityCode || '') + ']', activity.title].join(' ');
          children.push({
            key: activity.id,
            label,
            data: activity,
            icon: 'pi pi-fw pi-cog',
            children: [],
          });
        });
        */

        return node;
      }
      return [nodeFromContainer(this.playbook, 'Playbook')];
    },
  },
  watch: {
    selectedCategory(newVal) {
    },
    showCategorySidebar(newVal) {
      this.visible = this.showCategorySidebar;
    },
    selectedScope(newVal) {
    },
  },
  data() {
    return {
      visible: false,
      selectedScope: null,
    };
  },
  methods: {
    hideSlidebar() {
      this.$emit('update:showCategorySidebar', false);
    },
    createChangePayload() {
      return {
        playbookId: this.$store.state.assess.playbook._id,
        assessTaskId: this.$store.state.assess.playbook.assessTaskId,
        categoryId: this.selectedCategory.id,
      };
    },
    submitChangeToStore(action, payload) {
      this.$store.dispatch(action, payload).then(() => {
        this.$emit('categoryUpdated');
      });
    },
    propertyChanged(propertyName, event) {
      const newValue = event.target.innerText;
      const propertyChange = this.createChangePayload();
      propertyChange[propertyName] = newValue;
      this.submitChangeToStore('assess/updateCategory', propertyChange);
    },
    onSelectDestination(e) {
      const container = e.data;
      const propertyChange = this.createChangePayload();
      propertyChange.destinationContainerId = container.id;
      propertyChange.index = 0;
      this.$store.dispatch('assess/moveCategory', propertyChange);
    },
    moveIntoContainer(containerId) {
      this.$store.dispatch('assess/moveCategory', {
        categoryId: this.selectedCategory.id,
        destinationContainerId: this.selectedScope.id,
        index: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
