<template>
  <div class="graph">
    <svg :id="'svg_' + 0"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  emits: ['select-activity', 'select-category'],
  props: {
    container: Object,
  },
  computed: {
    hierarchy() {
      const root = d3.hierarchy(this.container, (n) => {
        return n?.categories?.concat(n?.activities);
      });

      root.descendants().forEach((d, i) => {
        d.id = i;
        d._children = d.children ? [...d.children] : undefined;

        //remove the nodes, for which there is no activity in the descendants
        d._children?.forEach((child) => {
          if (child.leaves().filter((l) => !l.data.activities).length === 0) {
            const index = d.children.indexOf(child);
            if (index > -1) {
              d.children.splice(index, 1);
            }
          }
        });
        //show only the first n levels (collapse)
        if (d.depth > 3) d.children = null;
      });

      return root;
    },
  },
  watch: {
    hierarchy(newValue) {
      this.updateChart();
    },
  },
  methods: {
    selectActivity(activity) {
      this.$emit('select-activity', activity);
    },
    selectCategory(category) {
      this.$emit('select-category', category);
    },
    getNodeType(node) {
      if (node.data.activityCode !== undefined) return 'activity';
      if (node.data._id) return 'playbook';
      return 'category';
    },
    selectNode(event, node) {
      if (this.getNodeType(node) === 'activity') {
        return this.selectActivity(node.data);
      }
      if (this.getNodeType(node) === 'category') {
        return this.selectCategory(node.data);
      }
      return false;
    },
    updateChart() {
      function drag(simulation) {
        function dragstarted(event, d) {
          // debugger;
          if (!event.active) simulation.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
        }

        function dragged(event, d) {
          d.fx = event.x;
          d.fy = event.y;
        }

        function dragended(event, d) {
          d.x = event.x;
          d.y = event.y;
          if (!event.active) simulation.alphaTarget(0);
          // d.fx = null;
          // d.fy = null;
        }

        return d3.drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended);
      }
      const svg = d3.select(`#svg_${0}`);
      const chartWidth = svg.node().clientWidth;
      const chartHeight = svg.node().clientHeight;
      svg.attr('viewBox', [0, 0, chartWidth, chartHeight]);
      svg.selectAll('*').remove();

      const svgFrame = svg.append('g');

      // svg.attr('height', `${chartHeight}px`);

      const tree = d3.tree()
        .size([chartWidth, chartHeight]);
      // .size([2 * Math.PI, chartWidth / 2])
      // .separation((a, b) => (a.parent === b.parent ? 1 : 2) / a.depth);

      const root = tree(this.hierarchy);
      const links = root.links();
      const nodes = root.descendants();

      const simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink(links).id((d) => d.id).distance(120).strength(1))
        .force('charge', d3.forceManyBody().strength(-500))
        //.force('center', d3.forceCenter(chartWidth / 2, chartHeight / 2).strength(1))
        .force('x', d3.forceX((d) => d.x))
        .force('y', d3.forceY((d) => d.y))
        .force('collision', d3.forceCollide().radius((d) => {
          return 60;
        }));

      const link = svgFrame.append('g')
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.6)
        .selectAll('line')
        .data(links)
        .join('line');

      const node = svgFrame.append('g')
        .attr('class', 'ava-node')
        .attr('fill', '#fff')
        .attr('stroke', '#000')
        .attr('stroke-width', 1.5)
        .selectAll('g')
        .data(nodes)
        .join('g')
        .call(drag(simulation));

      node.on('click', (event, n) => this.selectNode(event, n));

      /*
      node.append('circle')
        .attr('fill', (d) => (d.children ? null : '#000'))
        .attr('stroke', (d) => (d.children ? null : '#fff'))
        .attr('r', 13.5)
        .attr('cx', 0)
        .attr('cy', 0);
      */

      node.append('text')
        .text((d) => d.data.title)
        .attr('class', (d) => this.getNodeType(d))
        .attr('fill', 'white')
        .attr('font-size', '1.5rem')
        .attr('stroke', 'transparent')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('x', 0)
        .attr('y', 0);

      function zoomed({ transform }) {
        svgFrame.attr('transform', transform);
      }
      svg.call(d3.zoom()
        .extent([[0, 0], [chartWidth, chartHeight]])
        .scaleExtent([0.5, 2])
        .on('zoom', zoomed));

      simulation.on('tick', () => {
        const radius = 20;
        function boundX(x) {
          return Math.max(radius, Math.min(chartWidth - radius, x));
        }
        function boundY(y) {
          return Math.max(radius, Math.min(chartHeight - radius, y));
        }
        link
          .attr('x1', (d) => boundX(d.source.x))
          .attr('y1', (d) => boundY(d.source.y))
          .attr('x2', (d) => boundX(d.target.x))
          .attr('y2', (d) => boundY(d.target.y));

        node.attr('transform', (d) => {
          const x = boundX(d.x);
          const y = boundY(d.y);
          return `translate(${x},${y})`;
        });

        /*
        node
          .attr('cx', (d) => d.x)
          .attr('cy', (d) => d.y);
        */
      });
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateChart);
    this.updateChart();
  },
  unmounted() {
    window.removeEventListener('resize', this.updateChart);
  },
};
</script>

<style lang="scss" scoped>

.graph {
  flex-grow: 1;
  /* border: thin solid yellow; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  svg {
    flex-grow: 1;
    /* border: thin solid orange; */
    // width: 100%;
    // height: 100%;
    :deep {
      .playbook {
        font-weight: bolder;
        fill: var(--primary-color);
      }
      .category {
        font-weight: bolder;
        fill: var(--primary-color);
      }
      .activity {
        fill: var(--text-color);
      }
}

  }
}
</style>
