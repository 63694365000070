<template>
  <div class="document">
    <div @click="$emit('select-category', category)" class="category-title">
      <div class="text"><span v-if="displayNumbering">{{ container.autoNumbering.join('.') }}{{ container.autoNumbering.length > 0 ? '.' : ''}} </span> {{ container.title }}</div>
    </div>
    <template v-for="activity in container.activities" :key="activity.key">
      <div class="activity">
        <div @click="$emit('select-activity', activity)" class="activity-title">{{ activity.title }} ({{ activity.activityCode }})</div>
        <tipTap @save-text="propertyChanged(activity, 'summary', $event)" v-model="activity.summary" />
      </div>
    </template>
    <template v-for="category in container.categories" :key="category.key">
      <playbook-view-document-category
        :container="category"
        @select-activity="selectActivity"
        @select-category="selectCategory"
      />
    </template>
  </div>
</template>

<script>
import TipTap from '@/components/TipTap.vue';

export default {
  emits: ['select-activity', 'select-category', 'activityUpdated'],
  name: 'PlaybookViewDocumentCategory',
  components: {
    TipTap,
  },
  props: {
    container: Object,
  },
  computed: {
    navigation() {
      return this.$store.state.navigation;
    },
    displayNumbering() {
      return this.$store.state.navigation.displayNumbering;
    },
    filter() {
      return this.$store.state.navigation.filters['playbook.view.global'];
    },
  },
  data() {
    return {
      summary: 'take notes here',
    };
  },
  methods: {
    createChangePayload(activity) {
      return {
        playbookId: this.$store.state.assess.playbook._id,
        assessTaskId: this.$store.state.assess.playbook.assessTaskId,
        activityId: activity.id,
      };
    },
    propertyChanged(activity, propertyName, text) {
      const newValue = text;
      const propertyChange = this.createChangePayload(activity);
      propertyChange[propertyName] = newValue;
      this.submitChangeToStore('assess/updateActivity', propertyChange);
    },
    submitChangeToStore(action, payload) {
      this.$store.dispatch(action, payload).then(() => {
        this.$emit('activityUpdated');
      });
    },
    selectActivity(activity) {
      this.$emit('select-activity', activity);
    },
    selectCategory(category) {
      this.$emit('select-category', category);
    },
    showContextMenuForCategory(event, container) {
      if (container._id) {
        this.categoryForContextMenu = undefined; // it is a playbook
      } else {
        this.categoryForContextMenu = container;
      }
      this.$refs.categoryContextMenu.show(event);
    },
    showContextMenuForActivity(event, activity) {
      this.activityForContextMenu = activity;
      this.$refs.activityContextMenu.show(event);
    },
    percentageComplete(activity) {
      const val = activity.additionalProperties['Percentage Complete']?.value ?? 0;
      return val * 100;
    },
    colorFromActivity(activity) {
      switch (activity.status) {
        case 'In Progress':
          return 'var(--secondary-color)';
        case 'Completed':
          return 'var(--primary-color)';
        case 'Cancelled':
          return 'var(--text-color-secondary)';
        case 'On Hold':
          return 'var(--text-color-secondary)';
        default:
          return 'var(--text-color)';
      }
    },
    startDateColor(activity) {
      if (activity.lateToStart) {
        return 'var(--warning-color)';
      }
      return 'var(--primary-color)';
    },
    dueDateColor(activity) {
      if (activity.lateToFinish) {
        return 'var(--warning-color)';
      }
      return 'var(--primary-color)';
    },
    addCategory(categoryId) {
      this.$store.dispatch('assess/createCategory', {
        playbook: this.$store.state.assess.playbook,
        data: {
          title: 'Untitled category',
        },
        containerId: categoryId,
      });
    },
    addActivity(categoryId) {
      this.$store.dispatch('assess/createActivity', {
        playbook: this.$store.state.assess.playbook,
        data: {
          title: 'Untitled activity',
        },
        containerId: categoryId,
      });
    },
    archiveCategory(categoryId) {
      this.$store.dispatch('assess/archiveCategory', {
        playbook: this.$store.state.assess.playbook,
        categoryId,
      });
    },
    unarchiveCategory(categoryId) {
      this.$store.dispatch('assess/unarchiveCategory', {
        playbook: this.$store.state.assess.playbook,
        categoryId,
      });
    },
    archiveActivity(activityId) {
      this.$store.dispatch('assess/archiveActivity', {
        playbook: this.$store.state.assess.playbook,
        activityId,
      });
    },
    unarchiveActivity(activityId) {
      this.$store.dispatch('assess/unarchiveActivity', {
        playbook: this.$store.state.assess.playbook,
        activityId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.document {
  background: var(--surface-a);
}
.category-title {
  cursor: pointer;
  display: flex;
  text-align: left;
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-top: 1rem;
}

.activity {
  display: flex;
  flex-direction: column;
  text-align: left;

  .activity-title {
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--secondary-color);
    margin-top: 1rem;
    margin-bottom: 0.9rem;
  }

  .activity-text {
    font-size: 1.1rem;
    margin-top: 0.2rem;
  }

  :deep(.editor) {
    background-color: var(--surface-a);
    .editor__header {
      background-color: white;
    }
  }

  :deep([contenteditable]) {
    padding: 0rem;
    border: none;
    background-color: var(--surface-a);
  }

}

.table {
.archived {
  color: var(--primary-color-transparent);
  .category-title {
    color: var(--primary-color-transparent);
    border: 2px solid var(--primary-color-transparent);
  }
  table {
    border: 2px solid var(--primary-color-transparent);
  }
}
}

.table {
  background-color: var(--surface-a);
  table {
    margin-bottom: 2rem;
    cursor: pointer;
    font-size: 1.1rem;
    width: 100%;
    max-width: 100%;
    text-align: left;
    border-collapse: collapse;
    border: 2px solid var(--primary-color);
    table-layout: fixed;

    tr:hover {
      background-color: var(--primary-color-transparent);
      button {
        opacity: 1;
      }
    }

    tr.archived {
      color: var(--primary-color-transparent);
    }

    tr {
      button {
        opacity: 0;
      }
      /* border-bottom: thin solid var(--primary-color-transparent); */

      td {
        white-space: nowrap;
        padding: 0.6rem;
        text-overflow: ellipsis;
        overflow: hidden;
        border: thin solid var(--primary-color-transparent);
        border-top: thin solid transparent;
        background-color: var(--surface-a);

        .counter {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }
      }

      td:nth-child(1) {
        width: 1.5rem;
        color: var(--danger-color);
        text-align: center;
      }
      td:nth-child(2) {
        width: 3rem;
        text-align: center;
      }
      td:nth-child(3) {
        width: 14rem;
        text-align: left;
      }
      td:nth-child(4) {
        width: 4rem;
        text-align: center;
      }
      td:nth-child(5) {
        width: 6rem;
        text-align: center;
      }
      td:nth-child(6) {
        width: 6rem;
        text-align: center;
      }
      td:nth-child(7) {
        width: 6rem;
        text-align: center;
      }
      td:nth-child(8) {
        width: 6rem;
        text-align: center;
      }
    }
  }
}
</style>
