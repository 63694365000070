/* eslint-disable max-len */
<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  /* background-color: #000c20; */
  /* width: fit-content; */
}

html {
  background-color: var(--surface-a);
}

* {
  box-sizing: border-box;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

*::-webkit-scrollbar {
  display: none;  /* Chrome Safari */
}

#app {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  /* background-color: #000c20; */
  color: var(--text-color);
  display: flex;
  min-height: fit-content;
}
</style>
