<template>
  <ElementSidebar
    :situation="situation"
    :selectedElement="selectedElement"
    :elementType="selectedElementType"
    @elementUpdated="drawSituation"
    v-model:showElementSidebar="elementPanelIsVisible"
  ></ElementSidebar>
  <div class="main">
    <div class="view-toolbar">
    <div class="actions">
      <Button
        label="Back to register"
        @click="$router.push('/risks/register')"
        class="p-button-outlined mr-2"
      />
      <Button
        label="Recompute layout"
        @click="drawSituation"
        class="p-button-outlined mr-2"
      />
    </div>
    <div class="toggles">
      Causes <InputSwitch v-model="showSeeds" class="ml-2 mr-4"/>
      Proactive actions <InputSwitch v-model="showProactiveActions" class="ml-2 mr-4"/>
      Reactive actions <InputSwitch v-model="showReactiveActions" class="ml-2 mr-4"/>
      Outcomes <InputSwitch v-model="showOutcomes" class="ml-2"/>
    </div>
    </div>
    <div class="chart" @contextmenu="showContextMenuSituation">
      <svg :id="'svg_' + 0"></svg>
      <ContextMenu ref="contextMenuSituation" :model="contextMenuItemsSituation" />
      <ContextMenu ref="contextMenuSeed" :model="contextMenuItemsSeed" />
      <ContextMenu ref="contextMenuProactiveAction" :model="contextMenuItemsProactiveAction" />
      <ContextMenu ref="contextMenuEvent" :model="contextMenuItemsEvent" />
      <ContextMenu ref="contextMenuReactiveAction" :model="contextMenuItemsReactiveAction" />
      <ContextMenu ref="contextMenuOutcome" :model="contextMenuItemsOutcome" />
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
// eslint-disable-next-line camelcase
import d3_save_pdf from 'd3-save-pdf';
import ElementSidebar from './ElementSidebar.vue';

const saveSvgAsPng = require('save-svg-as-png');

export default {
  emits: [],
  components: {
    ElementSidebar,
  },
  computed: {
    situation() {
      return this.$store.state.butterfly.currentSituation;
    },
    cardInnerWidth() {
      return this.cardOuterWidth - 2 * this.cardPadding;
    },
    cardInnerHeight() {
      return this.cardOuterHeight - 3 * this.cardPadding;
    },
  },
  watch: {
    situation(newValue) {
      this.drawSituation();
    },
    showSeeds() {
      this.drawSituation();
    },
    showOutcomes() {
      this.drawSituation();
    },
    showProactiveActions() {
      this.drawSituation();
    },
    showReactiveActions() {
      this.drawSituation();
    },
  },
  data() {
    return {
      showSeeds: true,
      showOutcomes: true,
      showProactiveActions: true,
      showReactiveActions: true,
      selectedElement: {},
      selectedElementType: '',
      elementPanelIsVisible: false,
      cardOuterWidth: 160,
      cardOuterHeight: 120,
      cardPadding: 10,
      cardCornerRadius: 8,
      chainDeltaY: 50,
      boxes: [
        { label: 'Employee is bored' },
        { label: 'Employee is underpaid' },
      ],
      elementUnderLastContextMenuEvent: undefined,
      contextMenuItemsSituation: [
        {
          label: 'Edit situation',
          icon: 'pi pi-fw pi-pencil',
          command: () => {
            this.selectElement(undefined, this.situation, 'situation');
          },
        },
      ],
      contextMenuItemsSeed: [
        {
          label: 'Add proactive action',
          icon: 'pi pi-fw pi-plus',
          command: () => {
            this.$store.dispatch('butterfly/createProactiveAction', { situation: this.situation, seedToEventChain: this.elementUnderLastContextMenuEvent.chain }).then(() => this.drawSituation());
          },
        },
        {
          label: 'Delete',
          icon: 'pi pi-fw pi-trash',
          command: () => {
            this.$store.dispatch('butterfly/deleteSeed', { situationId: this.situation.id, elementId: this.elementUnderLastContextMenuEvent.id }).then(() => this.drawSituation());
          },
        },
      ],
      contextMenuItemsProactiveAction: [
        {
          label: 'Delete',
          icon: 'pi pi-fw pi-trash',
          command: () => {
            this.$store.dispatch('butterfly/deleteProactiveAction', { situationId: this.situation.id, elementId: this.elementUnderLastContextMenuEvent.id }).then(() => this.drawSituation());
          },
        },
      ],
      contextMenuItemsEvent: [
        {
          label: 'Add cause',
          icon: 'pi pi-fw pi-plus',
          command: (event) => {
            this.$store.dispatch('butterfly/createSeed', this.situation).then(() => this.drawSituation());
          },
        },
        {
          label: 'Add outcome',
          icon: 'pi pi-fw pi-plus',
          command: (event) => {
            this.$store.dispatch('butterfly/createOutcome', this.situation).then(() => this.drawSituation());
          },
        },
        {
          label: 'Export PNG',
          icon: 'pi pi-fw pi-image',
          command: (event) => {
            d3_save_pdf.save(d3.select('#svg_0').node(), { filename: 'workaround-for-fonts' });
            saveSvgAsPng.saveSvgAsPng(document.getElementById('svg_0'), 'butterfly.png', { scale: 1.5 });
          },
        },
        {
          label: 'Export SVG',
          icon: 'pi pi-fw pi-image',
          command: (event) => {
            const config = {
              filename: 'butterfly',
            };
            d3_save_pdf.save(d3.select('#svg_0').node(), config);
          },
        },
      ],
      contextMenuItemsReactiveAction: [
        {
          label: 'Delete',
          icon: 'pi pi-fw pi-trash',
          command: () => {
            this.$store.dispatch('butterfly/deleteReactiveAction', { situationId: this.situation.id, elementId: this.elementUnderLastContextMenuEvent.id }).then(() => this.drawSituation());
          },
        },
      ],
      contextMenuItemsOutcome: [
        {
          label: 'Add reactive action',
          icon: 'pi pi-fw pi-plus',
          command: () => {
            this.$store.dispatch('butterfly/createReactiveAction', { situation: this.situation, eventToOutcomeChain: this.elementUnderLastContextMenuEvent.chain }).then(() => this.drawSituation());
          },
        },
        {
          label: 'Delete',
          icon: 'pi pi-fw pi-trash',
          command: () => {
            this.$store.dispatch('butterfly/deleteOutcome', { situationId: this.situation.id, elementId: this.elementUnderLastContextMenuEvent.id }).then(() => this.drawSituation());
          },
        },
      ],
    };
  },
  methods: {
    async onLoggedIn() {
      await this.$store.dispatch('butterfly/getSituation', this.$route.params.id);
      this.loaded = true;
    },
    showContextMenuSituation(e) {
      this.elementUnderLastContextMenuEvent = this.situation;
      this.$refs.contextMenuSituation.show(e);
    },
    selectElement(event, element, elementType) {
      this.selectedElement = element;
      this.selectedElementType = elementType;
      this.elementPanelIsVisible = true;
    },
    chartSize() {
      const svg = d3.select(`#svg_${0}`);
      const chartWidth = svg.node().clientWidth;
      const chartHeight = svg.node().clientHeight;
      return [chartWidth, chartHeight];
    },
    drawEventBox(selection, text, type) {
      selection.append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', this.cardInnerWidth / 2)
        .classed('ava-svg-card-wrapper', true);
      selection.append('foreignObject')
        .attr('x', -this.cardInnerWidth / 2)
        .attr('y', -this.cardInnerWidth / 2)
        .attr('width', this.cardInnerWidth)
        .attr('height', this.cardInnerWidth)
        .append('xhtml:div')
        .classed('ava-svg-text-container', true)
        .append('xhtml:div')
        .classed('ava-svg-text', true)
        .classed('inverse', true)
        .append('xhtml:span')
        .text((d) => `${d.label} (${d.residualSeverity})`);
    },
    drawBox(selection, type) {
      const boxWidth = this.cardOuterWidth;
      const boxHeight = this.cardOuterHeight;
      const paddingTop = this.cardPadding;
      const paddingLeftRight = this.cardPadding;
      const innerBoxWidth = this.cardInnerWidth;
      const innerBoxHeight = this.cardInnerHeight;
      const cornerRadius = this.cardCornerRadius;

      selection.append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', boxWidth)
        .attr('height', boxHeight)
        .attr('rx', cornerRadius)
        .attr('ry', cornerRadius)
        .classed(`ava-${type}`, true)
        .classed('ava-svg-card-wrapper', true);

      selection.append('rect')
        .attr('x', paddingLeftRight)
        .attr('y', paddingTop)
        .attr('width', innerBoxWidth)
        .attr('height', innerBoxHeight)
        .attr('rx', cornerRadius)
        .attr('ry', cornerRadius)
        .classed('ava-svg-card-inner', true);

      const label = selection.append('foreignObject')
        .attr('x', paddingLeftRight)
        .attr('y', paddingTop)
        .attr('width', innerBoxWidth)
        .attr('height', innerBoxHeight)
        .append('xhtml:div')
        .classed('ava-svg-text-container', true)
        .append('xhtml:div')
        .classed('ava-svg-text', true)
        .append('xhtml:span');

      if (type === 'seed') {
        label.text((d) => `${d.label} (${d.residualLikelihoodLabel})`);
      }
      if (type === 'outcome') {
        label.text((d) => `${d.label} (${d.residualImpactLabel})`);
      }

      return selection;
    },
    drawActionBox(selection, text, type) {
      const boxWidth = this.cardInnerWidth;
      const boxHeight = this.cardInnerHeight;
      const cornerRadius = this.cardCornerRadius;
      const deltaY = this.chainDeltaY;

      selection.append('rect')
        .attr('x', 0)
        .attr('y', deltaY)
        .attr('width', boxWidth)
        .attr('height', boxHeight)
        .attr('rx', cornerRadius)
        .attr('ry', cornerRadius)
        .classed('ava-svg-card', true);

      const connectorRadius = 8;
      selection.append('circle')
        .classed('ava-svg-connector', true)
        .attr('cx', boxWidth / 2)
        .attr('cy', deltaY / 2)
        .attr('r', connectorRadius);
      selection.append('path')
        .attr('d', `M ${boxWidth / 2} ${deltaY / 2 + connectorRadius} V ${deltaY}`)
        .classed('ava-svg-connector', true);

      selection.append('foreignObject')
        .attr('x', 0)
        .attr('y', deltaY)
        .attr('width', boxWidth)
        .attr('height', boxHeight)
        .append('xhtml:div')
        .classed('ava-svg-text-container', true)
        .append('xhtml:div')
        .classed('ava-svg-text', true)
        .append('xhtml:span')
        .text((d) => `${d.label} (${d.currentEffectiveness}%)`);

      return selection;
    },
    drawEventBoxes(selection) {
      selection
        .attr('class', 'box-event')
        .attr('transform', `translate(${selection.datum().x}, ${selection.datum().y})`);
      this.drawEventBox(selection);
    },
    drawSeedBoxes(selection) {
      selection
        .attr('class', 'box-seed')
        .attr('transform', (d) => `translate(${d.x}, ${d.y})`);
      this.drawBox(selection, 'seed');
    },
    drawOutcomeBoxes(selection) {
      selection
        .attr('class', 'box-outcome')
        .attr('transform', (d) => `translate(${d.x}, ${d.y})`);
      this.drawBox(selection, 'outcome');
    },
    drawProactiveActionBoxes(selection) {
      selection
        .attr('class', 'box-proactiveAction')
        .attr('transform', (d) => `translate(${d.x}, ${d.y})`);
      this.drawActionBox(selection);
    },
    drawReactiveActionBoxes(selection) {
      selection
        .attr('class', 'box-reactiveAction')
        .attr('transform', (d) => `translate(${d.x}, ${d.y})`);
      this.drawActionBox(selection);
    },
    drawPaths(selection) {
      // const boxWidth = this.cardOuterWidth;
      const deltaY = this.chainDeltaY;
      selection
        .attr('class', 'seed-to-event-chain')
        .attr('d', (d) => {
          let path = 'M ';
          path += d[0][0];
          path += ' ';
          path += d[0][1] + deltaY / 2;
          for (let i = 1; i < d.length; i++) {
            path += 'L ';
            path += d[i][0];
            path += ' ';
            path += d[i][1] + deltaY / 2;
          }
          return path;
        });
    },
    computePositions(originalSituation, chartWidth, chartHeight) {
      const situation = originalSituation;
      let longestLeftChain = situation.seedToEventChains.length === 0 ? 0 : 1;
      longestLeftChain += Math.max(0, ...situation.seedToEventChains?.map((c) => { return c.proactiveActions?.length; }));
      let longestRightChain = situation.eventToOutcomeChains.length === 0 ? 0 : 1;
      longestRightChain += Math.max(0, ...situation.eventToOutcomeChains?.map((c) => { return c.reactiveActions?.length; }));
      const numberOfSlots = longestLeftChain + 1 + longestRightChain;
      const slotWidth = 1 / numberOfSlots;
      situation.event.x = chartWidth / 2;
      if (longestLeftChain !== 0 && longestRightChain === 0) {
        situation.event.x = chartWidth * 0.8 - this.cardInnerWidth / 2;
      } else if (longestLeftChain === 0 && longestRightChain !== 0) {
        situation.event.x = chartWidth * 0.2 + this.cardInnerWidth / 2;
      } else if (longestLeftChain !== 0 && longestRightChain !== 0) {
        situation.event.x = chartWidth * ((longestLeftChain * slotWidth) + slotWidth / 2);
      }
      situation.event.y = chartHeight * 0.5;

      situation.leftChainPaths = [];
      situation.seedToEventChains?.forEach((c, index1) => {
        if (longestRightChain === 0) {
          c.seed.x = 0.2 * chartWidth;
        } else {
          c.seed.x = 0;
        }
        const slotHeight = 1 / situation.seedToEventChains.length;
        c.seed.y = chartHeight * ((index1) * slotHeight + (slotHeight / 2)) - (this.cardOuterHeight / 2);
        const positionsLeft = [[c.seed.x, c.seed.y], [situation.event.x - this.cardInnerWidth / 2, c.seed.y], [situation.event.x, situation.event.y]];
        situation.leftChainPaths.push(positionsLeft);
        const spaceX = (positionsLeft[1][0] - positionsLeft[0][0]) / (c.proactiveActions.length + 1);
        c.proactiveActions?.forEach((a, index2) => {
          a.x = positionsLeft[0][0] + (index2 + 1) * spaceX;
          a.y = c.seed.y;
        });
      });

      situation.rightChainPaths = [];
      situation.eventToOutcomeChains?.forEach((c, index1) => {
        const slotHeight = 1 / situation.eventToOutcomeChains.length;
        if (longestLeftChain === 0) {
          c.outcome.x = 0.8 * chartWidth - this.cardOuterWidth;
        } else {
          c.outcome.x = chartWidth - this.cardOuterWidth;
        }
        c.outcome.y = chartHeight * ((index1) * slotHeight + (slotHeight / 2)) - (this.cardOuterHeight / 2);

        const positionsRight = [[situation.event.x, situation.event.y], [situation.event.x + this.cardInnerWidth / 2, c.outcome.y], [c.outcome.x, c.outcome.y]];
        situation.rightChainPaths.push(positionsRight);
        const spaceX = (positionsRight[2][0] - positionsRight[1][0]) / (c.reactiveActions.length + 1);
        c.reactiveActions?.forEach((a, index2) => {
          a.x = positionsRight[1][0] + (index2 + 1) * spaceX - this.cardInnerWidth / 2;
          a.y = c.outcome.y;
        });
      });

      return situation;
    },
    drawSlots() {
      if (true) return;
      console.log('draw slots');
      // debugger;
      const svg = d3.select(`#svg_${0}`);
      const chartWidth = svg.node().clientWidth;
      const chartHeight = svg.node().clientHeight;
      const longestLeftChain = Math.max(0, ...this.situation.seedToEventChains?.map((c) => { return c.proactiveActions?.length; })) + 1;
      const longestRightChain = Math.max(0, ...this.situation.eventToOutcomeChains?.map((c) => { return c.reactiveActions?.length; })) + 1;
      const numberOfHorizontalSlots = longestLeftChain + 1 + longestRightChain;
      const slotWidth = chartWidth / numberOfHorizontalSlots;
      for (let i = 0; i < numberOfHorizontalSlots; i++) {
        svg.append('line')
          .attr('x1', i * slotWidth)
          .attr('y1', 0)
          .attr('x2', i * slotWidth)
          .attr('y2', chartHeight)
          .attr('stroke', 'red');
      }
      const numberOfVerticalSlots = Math.max(this.situation.seedToEventChains.length, this.situation.eventToOutcomeChains.length);
      const slotHeight = chartHeight / numberOfVerticalSlots;
      for (let i = 0; i < numberOfVerticalSlots; i++) {
        svg.append('line')
          .attr('x1', 0)
          .attr('y1', i * slotHeight)
          .attr('x2', chartWidth)
          .attr('y2', i * slotHeight)
          .attr('stroke', 'red');
      }
    },
    drawSituation() {
      const svg = d3.select(`#svg_${0}`);
      const chartWidth = svg.node().clientWidth;
      const chartHeight = svg.node().clientHeight;
      svg.attr('viewBox', [0, 0, chartWidth, chartHeight]);
      svg.selectAll('*').remove();

      if (!this.situation) {
        return;
      }

      const situationWithPositions = this.computePositions(this.situation, chartWidth, chartHeight);
      this.drawSlots();

      if (this.showSeeds) {
        const leftPaths = svg.selectAll('g.seed-to-event-chain')
          .data(situationWithPositions.leftChainPaths)
          .join('path');
        this.drawPaths(leftPaths);
      }

      if (this.showOutcomes) {
        const rightPaths = svg.selectAll('g.event-to-outcome-chain')
          .data(situationWithPositions.rightChainPaths)
          .join('path');
        this.drawPaths(rightPaths);
      }

      const eventNodes = svg.selectAll('g.event')
        .data([situationWithPositions.event])
        .join('g')
        .on('click', (event, n) => this.selectElement(event, n, 'Event'))
        .on('contextmenu', (e, d) => { e.preventDefault(); this.elementUnderLastContextMenuEvent = d; this.$refs.contextMenuEvent.show(e); });
      this.drawEventBoxes(eventNodes);

      if (this.showSeeds) {
        const seedNodes = svg.selectAll('g.seed')
          .data(situationWithPositions.seedToEventChains?.map((c) => { return { ...c.seed, seed: c.seed, chain: c }; }))
          .join('g')
          .on('click', (event, n) => this.selectElement(event, n.seed, 'Seed'))
          .on('contextmenu', (e, d) => { e.preventDefault(); this.elementUnderLastContextMenuEvent = d; this.$refs.contextMenuSeed.show(e); });
        this.drawSeedBoxes(seedNodes);
      }

      if (this.showOutcomes) {
        const outcomeNodes = svg.selectAll('g.outcome')
          .data(situationWithPositions.eventToOutcomeChains?.map((c) => { return { ...c.outcome, outcome: c.outcome, chain: c }; }))
          .join('g')
          .on('click', (event, n) => this.selectElement(event, n.outcome, 'Outcome'))
          .on('contextmenu', (e, d) => { e.preventDefault(); this.elementUnderLastContextMenuEvent = d; this.$refs.contextMenuOutcome.show(e); });
        this.drawOutcomeBoxes(outcomeNodes);
      }

      if (this.showSeeds && this.showProactiveActions) {
        // eslint-disable-next-line prefer-spread
        const merged = [].concat.apply([], situationWithPositions.seedToEventChains?.map((c) => c.proactiveActions));
        const proactiveActionNodes = svg.selectAll('g.proactiveAction')
          .data(merged)
          .join('g')
          .on('click', (event, n) => this.selectElement(event, n, 'ProactiveAction'))
          .on('contextmenu', (e, d) => { e.preventDefault(); this.elementUnderLastContextMenuEvent = d; this.$refs.contextMenuProactiveAction.show(e); });
        this.drawProactiveActionBoxes(proactiveActionNodes, chartWidth, chartHeight);
      }

      if (this.showOutcomes && this.showReactiveActions) {
        // eslint-disable-next-line prefer-spread
        const merged = [].concat.apply([], situationWithPositions.eventToOutcomeChains?.map((c) => c.reactiveActions));
        const reactiveActionNodes = svg.selectAll('g.proactiveAction')
          .data(merged)
          .join('g')
          .on('click', (event, n) => this.selectElement(event, n, 'ReactiveAction'))
          .on('contextmenu', (e, d) => { e.preventDefault(); this.elementUnderLastContextMenuEvent = d; this.$refs.contextMenuReactiveAction.show(e); });
        this.drawReactiveActionBoxes(reactiveActionNodes, chartWidth, chartHeight);
      }
    },
  },
  mounted() {
    this.$store.dispatch('butterfly/getSituation', this.$route.params.id);
    this.$nextTick(() => {
      // window.addEventListener('resize', this.$nextTick(() => this.drawSituation));
      this.drawSituation();
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.drawSituation);
  },
};
</script>

<style lang="scss">
.seed-to-event-chain {
  stroke: var(--primary-color);
  fill: none;
  stroke-width: 2;
}
.ava-svg-connector {
  fill: var(--surface-a);
  stroke: var(--primary-color);
  stroke-width: 2;
}
.ava-svg-text-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: stretch;
  .ava-svg-text {
    flex-grow: 1;
    display: flex;
    justify-content: stretch;
    align-items: center;
    color: var(--text-color);
    font-weight: 700;
    padding: 0.5rem;

    span {
      flex-grow: 1;
      text-align: center;
    }
  }
  .inverse {
    color: var(--surface-a);
  }
}

.ava-svg-card-wrapper {
  fill: var(--primary-color);
}

.ava-svg-card-inner {
  fill: var(--surface-a);
  //stroke: var(--primary-color);
  //stroke-width: 2;
}

.ava-svg-card {
  fill: var(--surface-a);
  stroke: var(--primary-color);
  stroke-width: 2;
}

.ava-seed {
  fill: var(--warning-color);
}

.ava-outcome {
  fill: var(--danger-color);
}

</style>

<style lang="scss" scoped>

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // border: thin solid blue;
  margin-bottom: 2rem;
  max-height: 80rem;
  .view-toolbar {
    display: flex;
    justify-content: space-between;
    .toggles {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .chart {
    // border: thin solid red;
    flex-grow: 1;
    // height: 50rem;
    //width: min-content;
    //height: min-content;
    display: flex;
    align-items: stretch;
    height: 100%;
    svg {
      flex-grow: 1;
      //border: thin solid yellow;
    }
  }
}

</style>
