<template>
  <div class="gantt">
    <div class="activity-group">
      <div class="group-title">Activities with start and due dates</div>
      <div class="activities">
        <svg :id="'svg_' + 0"></svg>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

function flatten(container, parentIds, activities, categories) {
  activities.push(
    ...container.activities.map((activity) => {
      return { ...activity, parentIds };
    }),
  );
  categories.push(
    ...container.categories.map((category) => {
      return { ...category, parentIds };
    }),
  );
  container.categories.forEach((category) => {
    flatten(
      category,
      [...parentIds, category.id],
      activities,
      categories,
    );
  });
  return {
    activities: activities.filter(
      (activity) => activity.startDate && activity.dueDate,
    ),
    categories,
  };
}

export default {
  emits: ['select-activity'],
  computed: {
    playbook() {
      return this.$store.state.assess.playbook;
    },
    activities() {
      const flat = flatten(this.playbook, [], [], []);
      return flat.activities;
    },
  },
  watch: {
    activities(newValue) {
      this.updateGantt();
    },
  },
  methods: {
    colorFromActivity(activity) {
      if (activity.atRisk) {
        return 'red';
      }
      switch (activity.status) {
        case 'In Progress':
          return '#00d2ff';
        case 'Completed':
          return 'white';
        case 'Canceled':
          return 'gray';
        case 'Not Started':
          return 'gray';
        default:
          return 'gray';
      }
    },
    updateGantt() {
      const minDate = d3.min(
        this.activities.filter((d) => d.startDate),
        (d) => new Date(d.startDate),
      );
      const maxDate = d3.max(
        this.activities.filter((d) => d.dueDate),
        (d) => new Date(d.dueDate),
      );

      const barHeight = 30;
      const barPadding = 2;
      //this.activities.forEach((parent, index) => {
      const svg = d3.select(`#svg_${0}`);
      const chartWidth = svg.node().clientWidth;
      const chartHeight = barHeight * this.activities.length;
      const timeScale = d3
        .scaleTime()
        .domain([minDate, maxDate])
        .range([0, chartWidth]);
      svg.attr('height', `${chartHeight}px`);

      svg.selectAll('*').remove();

      const bars = svg
        .selectAll('g')
        // .data(parent[1])
        .data(this.activities)
        .enter()
        .append('g')
        .attr('x', (d) => timeScale(new Date(d.startDate)))
        .attr('y', (d, i) => i * barHeight + barPadding)
        .attr(
          'width',
          (d) => timeScale(new Date(d.dueDate))
            - timeScale(new Date(d.startDate))
            + 1,
        )
        .attr('height', barHeight - 2 * barPadding);

      bars
        .append('rect')
        .attr('x', (d) => timeScale(new Date(d.startDate)))
        .attr('y', (d, i) => i * barHeight + barPadding)
        .attr(
          'width',
          (d) => timeScale(new Date(d.dueDate))
            - timeScale(new Date(d.startDate))
            + 1,
        )
        .attr('height', barHeight - 2 * barPadding)
        //.attr('stroke', '#00d2ff')
        .attr('fill-opacity', 0.3)
        .attr('fill', (d) => this.colorFromActivity(d));
      //.append('svg:title')
      //.text((d) => JSON.stringify(d));

      bars
        .append('rect')
        .attr('x', (d) => timeScale(new Date(d.startDate)))
        .attr('y', (d, i) => i * barHeight + barPadding)
        .attr('fill', (d) => this.colorFromActivity(d))
        .attr('width', 2)
        .attr('height', barHeight - 2 * barPadding);

      bars
        .append('text')
        .attr('x', (d) => timeScale(new Date(d.startDate)) + 8)
        .attr('y', (d, i) => i * barHeight + barPadding + barHeight / 2)
        .attr('text-anchor', 'left')
        .attr('alignment-baseline', 'middle')
        .attr('fill', (d) => this.colorFromActivity(d))
        .text((d) => `${d.status ? d.status : 'No status selected'}: ${d.title}`);
      //});

      bars
        .style('cursor', 'pointer');

      bars.on('click', (d, i) => {
        this.$emit('select-activity', i);
      });

      const nowX = timeScale(new Date());
      svg
        .append('line')
        .style('stroke', '#ffa200')
        .style('stroke-width', 2)
        .attr('x1', nowX)
        .attr('y1', 0)
        .attr('x2', nowX)
        .attr('y2', chartHeight);
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateGantt);
    this.updateGantt();
  },
  unmounted() {
    window.removeEventListener('resize', this.updateGantt);
  },
};
</script>

<style lang="scss" scoped>
.activity-group {
  padding: 2rem;
  .group-title {
    font-size: 1.8rem;
    color: #ffa200;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;
  }

  .activities {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.1rem;
    svg {
      /* border: thin solid rgba(255, 0, 0, 0.411); */
    }
  }
}
</style>
