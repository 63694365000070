<template>
  <Sidebar
    v-model:visible="visible"
    @hide="hideSlidebar"
    position="left"
    class="p-sidebar-lg avalia-sidebar-left"
  >
    <div class="list">
      <div class="sheet" v-for="sheet in smartsheet.sheets" :key="sheet.id">
        <Button
          @click="openInTab(sheet.permalink)"
          target="_blank"
          label="Open"
          icon="pi pi-eye"
          class="p-button"
        />
        <Button
          @click="importSmartsheet(sheet)"
          label="Import"
          icon="pi pi-cloud-download"
          class="p-button"
        />
        {{ sheet.name }}
      </div>
    </div>
  </Sidebar>
</template>

<script>
import * as d3 from 'd3';
import axios from 'axios';

export default {
  emits: ['update:showSidebar'],
  props: {
    showSidebar: Boolean,
  },
  computed: {
    assess() {
      return this.$store.state.assess;
    },
    smartsheet() {
      return this.$store.state.smartsheet;
    },
  },
  watch: {
    showSidebar(newVal) {
      this.visible = this.showSidebar;
    },
  },
  data() {
    return {
      visible: false,
      mapping: undefined,
    };
  },
  methods: {
    hideSlidebar() {
      this.$emit('update:showSidebar', false);
    },
    openInTab(url) {
      window.open(url, '_blank');
    },
    async importSmartsheet(smartsheetReference) {
      const { mapping } = this;
      const sheet = await this.$store.dispatch('smartsheet/fetchSheet', smartsheetReference.id);
      const columnsByTitle = new Map();
      sheet.columns.forEach((column) => {
        columnsByTitle.set(column.title, column);
      });

      function mapRowToActivity(row) {
        const activityFromRow = {
          id: row.id,
          parentId: row.parentId,
          additionalProperties: {},
        };
        mapping.columns.forEach((column) => {
          if (column.toPlaybook) {
            if (column.toPlaybook.property) {
              const value = row.cells.find(
                (cell) => cell.columnId === columnsByTitle.get(column.title)?.id,
              )?.value;
              if (value) {
                activityFromRow[column.toPlaybook.property] = typeof value === 'string' ? value.replace(/\n/g, ' ') : value;
              }
            }
            if (column.toPlaybook.additionalProperty) {
              const value = row.cells.find(
                (cell) => cell.columnId === columnsByTitle.get(column.title)?.id,
              )?.value;
              if (value) {
                activityFromRow.additionalProperties[
                  column.toPlaybook.additionalProperty
                ] = {
                  type: column.toPlaybook.type,
                  value: typeof value === 'string' ? value.replace(/\n/g, ' ') : value,
                };
              }
            }
          }
        });
        activityFromRow.resources = row.attachments ? row.attachments.map((a) => {
          return {
            name: a.name,
            link: a.url,
          };
        }) : [];
        activityFromRow.milestones = row.discussions ? row.discussions.map((d) => {
          return {
            name: `${d.createdBy?.name}: ${d.title}`,
            dueDate: d.lastCommentedAt,
          };
        }) : [];

        return activityFromRow;
      }

      let activitiesFromRows = sheet.rows.map(mapRowToActivity);
      activitiesFromRows = activitiesFromRows.filter((activity) => activity.title); // remove blank rows in the smartsheet

      activitiesFromRows.unshift({
        id: '$$ROOT',
        parentId: undefined,
      });
      const smartsheetRootNode = d3
        .stratify()
        .id((d) => d.id)
        .parentId((d) => (d.parentId || d.id === '$$ROOT' ? d.parentId : '$$ROOT'))(activitiesFromRows);

      this.$store.dispatch('assess/overwritePlaybookWithSmartsheet', {
        assessTaskId: this.assess.playbook.assessTaskId,
        playbook: this.assess.playbook,
        smartsheetRootNode,
      });
    },
  },
  mounted() {
    axios
      .get('/data/smartsheet-mapping-avalia.json')
      .then((response) => {
        this.mapping = response.data;
      });
  },
};
</script>

<style lang="scss" scoped>
.list {
  height: 100%;
  overflow: scroll;
  .sheet {
    display: flex;
    align-items: center;
    padding: 0.2rem;
    Button {
      margin-right: 1rem;
    }
  }
}
</style>
