<template>
  <main-layout @logged-in="onLoggedIn">
    <template v-slot:views>
      <i @click="$router.push('/playbooks')" class="pi pi-home" :class="{ current: currentView === 'home' }"></i>
      <i @click="currentView = 'playbookCards'" class="pi pi-th-large" :class="{ current: currentView === 'playbookCards' }"></i>
      <i @click="currentView = 'playbookTable'" class="pi pi-table" :class="{ current: currentView === 'playbookTable' }"></i>
      <i @click="currentView = 'playbookGantt'" class="pi pi-bars" :class="{ current: currentView === 'playbookGantt' }"></i>
      <i @click="currentView = 'playbookDocument'" class="pi pi-file" :class="{ current: currentView === 'playbookDocument' }"></i>
      <i @click="currentView = 'playbookEvents'" class="pi pi-clock" :class="{ current: currentView === 'playbookEvents' }"></i>
      <div class="fa" :class="{ current: currentView === 'playbookGraph' }"><font-awesome-icon @click="currentView = 'playbookGraph'" icon="project-diagram" size="lg" /></div>
    </template>

    <template v-slot:actions>
      <Button v-show="smartsheet.accessToken" @click="fetchSmartsheets" label="Import Smartsheet" icon="pi pi-cloud-download" class="p-button" />
    </template>

    <template v-slot:title>
      {{ filteredPlaybook.title }}
    </template>

    <template v-slot>
      <div v-if="loaded" class="view">
        <PlaybookActivitySidebar :playbook="filteredPlaybook" :selectedActivity="selectedActivity" @activityUpdated="activityUpdated" v-model:showActivitySidebar="activityPanelIsVisible"></PlaybookActivitySidebar>
        <PlaybookCategorySidebar :playbook="filteredPlaybook" :selectedCategory="selectedCategory" @categoryUpdated="categoryUpdated" v-model:showCategorySidebar="categoryPanelIsVisible"></PlaybookCategorySidebar>
        <SmartsheetSidebar v-model:showSidebar="smartsheetPanelIsVisible"></SmartsheetSidebar>
        <div v-if="currentView.startsWith('playbook')" class="navigation p-mb-4">
          <div class="pull-left">
            <TreeSelect v-model="selectedScope" :options="treeNodesFromPlaybook(false)" placeholder="Table of Content"> </TreeSelect>
            <InputText @input="applyFilter" type="text" placeholder="Filter activities..." v-model="filter" class="ml-4 mr-4" />
            Show archived: <InputSwitch v-model="showArchived" class="ml-2" />
          </div>
          <div class="pull-right">
            <Button label="Add category" @click.stop="addCategory()" class="p-button-outlined mr-2" />
            <Button label="Add activity" @click.stop="addActivity()" class="p-button-outlined" />
          </div>
        </div>
        <PlaybookContainer v-if="currentView === 'playbookCards'" :numbering="''" @select-activity="showActivityDetails" @select-category="showCategoryDetails" :container="selectedContainer" :playbook="filteredPlaybook" :recursive-query="{}"></PlaybookContainer>
        <PlaybookViewGantt v-if="currentView === 'playbookGantt'" @select-activity="showActivityDetails"> </PlaybookViewGantt>
        <PlaybookViewTable v-if="currentView === 'playbookTable'" @select-activity="showActivityDetails" @select-category="showCategoryDetails" :container="selectedContainer"> </PlaybookViewTable>
        <PlaybookViewDocument v-if="currentView === 'playbookDocument'" @select-activity="showActivityDetails" @select-category="showCategoryDetails" :container="selectedContainer"> </PlaybookViewDocument>
        <PlaybookViewEvents v-if="currentView === 'playbookEvents'" @select-activity="showActivityDetails" @select-category="showCategoryDetails" :container="selectedContainer"> </PlaybookViewEvents>
        <PlaybookViewGraph v-if="currentView === 'playbookGraph'" @select-activity="showActivityDetails" @select-category="showCategoryDetails" :container="selectedContainer"> </PlaybookViewGraph>
        <!--
      <PlaybookViewRiskRegister
        v-if="currentView === 'risksRegister'"
      >
      </PlaybookViewRiskRegister>
      <PlaybookViewButterfly
        v-if="currentView === 'risksButterfly'"
      >
      </PlaybookViewButterfly>
      --></div>
    </template>
  </main-layout>
</template>

<script>
import axios from 'axios';
import MainLayout from '@/layouts/MainLayout.vue';
import store from '@/store/';
import PlaybookViewDocument from './components/PlaybookViewDocument.vue';
import PlaybookViewEvents from './components/PlaybookViewEvents.vue';
import PlaybookViewGantt from './components/PlaybookViewGantt.vue';
import PlaybookViewGraph from './components/PlaybookViewGraph.vue';
import PlaybookViewTable from './components/PlaybookViewTable.vue';
import PlaybookActivitySidebar from './components/PlaybookActivitySidebar.vue';
import PlaybookCategorySidebar from './components/PlaybookCategorySidebar.vue';
import PlaybookContainer from './components/PlaybookContainer.vue';
import SmartsheetSidebar from './components/SmartsheetSidebar.vue';

export default {
  components: {
    MainLayout,
    PlaybookActivitySidebar,
    PlaybookCategorySidebar,
    SmartsheetSidebar,
    PlaybookContainer,
    PlaybookViewDocument,
    PlaybookViewEvents,
    PlaybookViewGantt,
    PlaybookViewGraph,
    PlaybookViewTable,
  },
  data() {
    return {
      loaded: false,
      selectedScope: null,
      currentView: 'playbookCards',
      selectedActivity: {},
      selectedCategory: {},
      activityPanelIsVisible: false,
      categoryPanelIsVisible: false,
      smartsheetPanelIsVisible: false,
      showOpenDialog: false,
      showArchived: false,
    };
  },
  computed: {
    navigation() {
      return this.$store.state.navigation;
    },
    filteredPlaybook() {
      return this.$store.getters['assess/filteredPlaybook'](this.showArchived, this.filter);
    },
    selectedContainer() {
      if (!this.selectedScope) {
        return this.filteredPlaybook;
      }
      const selectedScopeId = Object.keys(this.selectedScope)[0];
      if (this.selectedScope) {
        const categoryFromId = this.$store.getters['assess/getCategoryById'](this.filteredPlaybook, selectedScopeId);
        if (categoryFromId) {
          return categoryFromId;
        }
      }
      return this.filteredPlaybook;
    },
    treeNodesFromPlaybook() {
      return (includeActivities) => {
        const includeArchived = true;
        const that = this;
        function nodeFromContainer(container, containerType) {
          const id = container.id || container._id;

          let icon = 'pi-folder-open';
          switch (containerType) {
            case 'Playbook':
              icon = 'pi-book';
              break;
            default:
              icon = 'pi-folder-open';
          }
          const children = [];
          let label = '';
          if (that.navigation.displayNumbering) {
            label = `${container.autoNumbering?.join('.')}${container.autoNumbering?.length > 0 ? '. ' : ''} ${container.title}`;
          } else {
            label = container.title;
          }
          const node = {
            type: containerType,
            key: id,
            label,
            data: container,
            icon: `pi pi-fw ${icon}`,
            children,
          };
          container.categories?.forEach((category) => {
            if (includeArchived || !category.isArchived) {
              children.push(nodeFromContainer(category, 'Category'));
            }
          });
          if (includeActivities) {
            container.activities?.forEach((activity) => {
              if (includeArchived || !activity.isArchived) {
                children.push({
                  type: 'Activity',
                  key: activity.id,
                  label: activity.title,
                  data: activity,
                  icon: 'pi pi-fw pi-stop',
                  children: [],
                });
              }
            });
          }
          return node;
        }
        return [nodeFromContainer(this.filteredPlaybook, 'Playbook')];
      };
    },
    config() {
      return this.$store.state.assess.config;
    },
    smartsheet() {
      return this.$store.state.smartsheet;
    },
    filter: {
      get() {
        return this.$store.state.navigation.filters['playbook.view.global'];
      },
      set(value) {
        this.$store.commit('navigation/SET_FILTER', {
          name: 'playbook.view.global',
          value,
        });
      },
    },
  },
  methods: {
    showActivityDetails(activity) {
      this.selectedActivity = activity;
      this.activityPanelIsVisible = true;
    },
    showCategoryDetails(category) {
      this.selectedCategory = category;
      this.categoryPanelIsVisible = true;
    },
    activityUpdated() {
      this.selectedActivity = this.$store.getters['assess/getActivityById'](this.filteredPlaybook, this.selectedActivity.id); // this.playbookFlat.activities.find((a) => a.id === this.selectedActivity.id);
    },
    categoryUpdated() {
      this.selectedCategory = this.$store.getters['assess/getCategoryById'](this.filteredPlaybook, this.selectedCategory.id); // this.playbookFlat.categories.find((c) => c.id === this.selectedCategory.id);
    },
    async onLoggedIn() {
      console.log('onLoggedIn');
      await store.dispatch('assess/fetchPlaybook', this.$route.params.id).then((playbook) => {
        return store.dispatch('assess/fetchEventsByAssessTaskId', {
          playbookId: this.$store.state.assess.playbook._id,
          assessTaskId: this.$store.state.assess.playbook.assessTaskId,
        });
      });
      this.loaded = true;
    },
    connectWithSmartsheets() {
      const clientId = this.config['smartsheet.oauth.client.id'];
      axios.get(`https://smartsheet.avalia.io/api/auth?clientId=${clientId}`).then((response) => {
        const thisUrl = this.$route.path;
        window.location = `${response.data.authorizationUri}&state=${thisUrl}`;
      });
    },
    fetchSmartsheets() {
      this.$store.dispatch('smartsheet/fetchSheets');
      this.smartsheetPanelIsVisible = true;
    },
    applyFilter() {},
    addCategory() {
      this.$store.dispatch('assess/createCategory', {
        playbook: this.$store.state.assess.playbook,
        data: {
          title: 'Untitled category',
        },
        containerId: this.selectedContainer.id,
      });
    },
    addActivity() {
      this.$store.dispatch('assess/createActivity', {
        playbook: this.$store.state.assess.playbook,
        data: {
          title: 'Untitled activity',
        },
        containerId: this.selectedContainer.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .view {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // align-items: flex-start;
    padding-left: 2rem;
    padding-right: 2rem;

    .navigation {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
      }
    }
  }
</style>
