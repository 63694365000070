<template>
  <Sidebar
    v-model:visible="visible"
    @hide="hideSlidebar"
    position="right"
    class="p-sidebar-lg avalia-sidebar avalia-sidebar-right p-input-filled"
  >
  <div class="card">
    <div class="loading-spinner">
      <i v-if="requestsInFlight" class="pi pi-spin pi-spinner" />
    </div>
    <div class="title-bar">
      <div @click="toggleActivityFlag" class="icon">
        <i
          class="pi pi-flag"
          :class="{ flagged: selectedActivity.isFlagged }"
        />
      </div>
      <div
        contenteditable
        @blur="propertyChanged('title', $event)"
        @keydown.enter.prevent="propertyChanged('title', $event)"
        class="title p-inputtext"
      >
        {{ selectedActivity.title }}
      </div>
    </div>
    <div class="fields">
      <div
        class="property"
        :class="{
          activity: true,
        }"
      >
        <div class="legend">Status</div>
        <Dropdown
          :modelValue="localStatus"
          @update:modelValue="statusModelUpdated"
          @change="statusChanged"
          @keyup.enter="statusChanged"
          @keydown.tab="statusChanged"
          appendTo="self"
          :options="states"
          :editable="true"
          optionLabel="label"
          optionValue="value"
          placeholder="Select a status"
        />
      </div>

      <div class="property">
        <div class="legend">Activity type</div>
        <Dropdown
          :modelValue="localActivityType"
          @update:modelValue="activityTypeModelUpdated"
          @change="activityTypeChanged"
          @keyup.enter="activityTypeChanged"
          @keydown.tab="activityTypeChanged"
          appendTo="self"
          :options="activityTypes"
          :editable="false"
          optionLabel="label"
          optionValue="value"
          placeholder="Select activity type"
        >
          <template #option="slotProps">
            <div class="activityTypesDropDown">
              <i :class="activityTypeIcon(slotProps.option.value)"></i>
              <div>{{ slotProps.option.label }}</div>
            </div>
          </template>
        </Dropdown>
      </div>

      <div class="property">
        <div class="legend">Activity Code</div>
        <div
          class="value p-inputtext"
          contenteditable
          @blur="propertyChanged('activityCode', $event)"
          @keydown.enter.prevent="propertyChanged('activityCode', $event)"
        >
          {{ selectedActivity.activityCode }}
        </div>
      </div>
      <div class="row">
        <div class="property">
          <div class="legend">Start date</div>
          <Calendar
            v-model="localStartDate"
            @date-select="datePropertyChanged('startDate', localStartDate)"
            :manualInput="false"
            dateFormat="MM d, yy"
            :class="{
              activity: true,
              lateToStart: selectedActivity.lateToStart,
            }"
          />
        </div>
        <div class="property">
          <div class="legend">Due date</div>
          <Calendar
            v-model="localDueDate"
            @date-select="datePropertyChanged('dueDate', localDueDate)"
            :manualInput="false"
            dateFormat="MM d, yy"
            :class="{
              activity: true,
              lateToFinish: selectedActivity.lateToFinish,
            }"
          />
        </div>
      </div>
      <!--
      <div class="property">
        <div class="legend">Predecessors</div>
        <AutoComplete forceSelection v-model="predecessor" :suggestions="filteredActivities" @complete="filterActivities($event)" field="title">
          <template #item="slotProps">
            <div class="p-autocomplete-item-activity">
              <div>{{slotProps.item.activityCode}}</div>
              <div>{{slotProps.item.title}}</div>
            </div>
          </template>
        </AutoComplete>
      </div>
      -->
      <div class="property">
        <div class="legend">Summary</div>
          <tipTap @save-text="richTextPropertyChanged('summary', localSummary)" v-model="localSummary" />
        <!--
        <div
          class="value p-inputtext"
          contenteditable
          @blur="propertyChanged('summary', $event)"
        >
          {{ selectedActivity.summary }}
        </div>
        -->
      </div>
      <div class="row">
        <div class="property">
          <div class="legend">Owner</div>
          <div
            class="value p-inputtext"
            contenteditable
            @blur="propertyChanged('owner', $event)"
            @keydown.enter.prevent="propertyChanged('owner', $event)"
          >
            {{ selectedActivity.owner }}
          </div>
        </div>
        <div class="property">
          <div class="legend">Client Focal Point</div>
          <div
            class="value p-inputtext"
            contenteditable
            @blur="propertyChanged('clientFocalPoint', $event)"
            @keydown.enter.prevent="propertyChanged('clientFocalPoint', $event)"
          >
            {{ selectedActivity.clientFocalPoint }}
          </div>
        </div>
      </div>
      <div
        class="property"
        v-for="(
          property, propertyName
        ) in selectedActivity.additionalProperties"
        :key="propertyName"
      >
        <div class="legend">{{ propertyName }}</div>
        <div
          v-if="property.type === 'date' && isDate(property.value)"
          class="value"
        >
          {{ distanceToNow(property.value) }}
          <span>{{ formatAsDay(property.value) }}</span>
        </div>
        <div v-else class="value">
          {{ property.value }}
        </div>
      </div>
      <div
        class="property"
        :class="{
          activity: true,
        }"
      >
        <div class="sectionTitle">
          <span>Conditions of completion</span>
          <Button
            @click="addConditionOfCompletion"
            icon="pi pi-plus"
            class="p-button-rounded"
          />
        </div>
        <div
          class="condition"
          v-for="condition in selectedActivity.conditions.filter((c) => !c.isArchived)"
          :key="condition.id"
        >
          <div class="group1">
            <div @click="toggleConditionOfCompletion(condition)" class="icon">
              <i v-if="condition.isComplete" class="pi pi-check-circle enabled" />
              <i v-if="!condition.isComplete" class="pi pi-circle-off disabled" />
            </div>
            <div
              contenteditable
              class="p-inputtext"
              @blur="updateConditionOfCompletion(condition, $event)"
              @keydown.enter.prevent="
                updateConditionOfCompletion(condition, $event)
              "
            >
              {{ condition.condition }}
            </div>
          </div>
          <div class="line"></div>
          <Button
            @click="archiveConditionOfCompletion(condition)"
            icon="pi pi-times"
            class="p-button-rounded small-button"
          />
        </div>

          <div class="sectionTitle">
            <span>Notes</span>
            <Button @click="addNote" icon="pi pi-plus" class="p-button-rounded" />
          </div>
          <div class="discussion" v-for="note in notes" :key="note.id">
            <div class="group1">
              <i class="pi pi-comment"></i>
              <div id="comment" class="p-inputtext" contenteditable @blur="updateNote(note, $event)">
                {{ note.text }}
              </div>
            </div>
            <div class="line"></div>
            <Button
              @click="deleteNote(note)"
              icon="pi pi-times"
              class="p-button-rounded small-button"
            />
          </div>

        <div class="sectionTitle">
          <span>Resources</span>
          <Button
            @click="addResource"
            icon="pi pi-plus"
            class="p-button-rounded"
          />
        </div>
        <div
          class="attachment"
          v-for="resource in selectedActivity.resources.filter((r) => !r.isArchived)"
          :key="resource.id"
        >
          <div class="group1">
            <a :href="resource.link" target="_blank">
              <i class="pi pi-bookmark"></i>
            </a>
            <div
              class="name p-inputtext"
              contenteditable
              @keydown.enter.prevent="
                resource.name = $event.target.innerText;
                updateResource(resource);
              "
              @blur="
                resource.name = $event.target.innerText;
                updateResource(resource, $event);
              "
            >
              {{ resource.name }}
            </div>
            <div class="link" v-show="resourcesInEditMode[resource.id]">
              <div>URL:</div>
              <div
                class="url p-inputtext"
                contenteditable
                @keydown.enter.prevent="
                  resource.link = $event.target.innerText;
                  updateResource(resource);
                  resourcesInEditMode[resource.id] = false;
                "
                @blur="
                  resource.link = $event.target.innerText;
                  updateResource(resource, $event);
                  resourcesInEditMode[resource.id] = false;
                "
              >
                {{ resource.link }}
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="group1">
            <Button
              @click="
                resourcesInEditMode[resource.id] = !resourcesInEditMode[
                  resource.id
                ]
              "
              :label="resourcesInEditMode[resource.id] ? 'Hide URL' : 'Show URL'"
              icon="pi pi-pencil"
              class="p-button-rounded small-button"
            />
            <Button
              @click="archiveResource(resource)"
              icon="pi pi-times"
              class="p-button-rounded small-button"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="comments">{{ selectedActivity.comments }}</div>
  </div>
  </Sidebar>
</template>

<script>
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { toDate, format, utcToZonedTime } from 'date-fns-tz';
import { isDate } from 'date-fns';
import TipTap from '@/components/TipTap.vue';

export default {
  emits: ['update:showActivitySidebar', 'activityUpdated'],
  props: {
    playbook: Object,
    selectedActivity: Object,
    showActivitySidebar: Boolean,
  },
  components: {
    TipTap,
  },
  computed: {
    activities() {
      return this.$store.getters['assess/activities'](this.playbook);
    },
    requestsInFlight() {
      return this.$store.state.assess.numberOfInflightRequests;
    },
    notes() {
      return this.selectedActivity.notes.filter((note) => !note.isArchived);
    },
    activityTypes() {
      return [
        {
          label: 'Individual',
          value: 'INDIVIDUAL',
        },
        {
          label: 'Coordinated',
          value: 'COORDINATED',
        },
      ];
    },
    states() {
      const customization = this.$store.state.assess.playbook
        ?.additionalProperties?.config_states?.value;
      const playbookStates = customization
        ? JSON.parse(customization)
        : undefined;
      if (playbookStates) {
        return playbookStates.map((s) => {
          return {
            label: s,
            value: s,
          };
        });
      }
      return [
        {
          label: 'Not Started',
          value: 'Not Started',
        },
        {
          label: 'In Progress',
          value: 'In Progress',
        },
        {
          label: 'Completed',
          value: 'Completed',
        },
        {
          label: 'Cancelled',
          value: 'Cancelled',
        },
        {
          label: 'On Hold',
          value: 'On Hold',
        },
      ];
    },
  },
  watch: {
    selectedActivity(newVal) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.localStatus = newVal.status;
      this.localActivityType = newVal.activityType;
      this.localSummary = newVal.summary;
      if (newVal.startDate) {
        this.localStartDate = utcToZonedTime(newVal.startDate, timezone);
      } else {
        this.localStartDate = 'Not specified';
      }
      if (newVal.dueDate) {
        this.localDueDate = utcToZonedTime(newVal.dueDate, timezone);
      } else {
        this.localDueDate = 'Not specified';
      }
    },
    showActivitySidebar(newVal) {
      this.visible = this.showActivitySidebar;
    },
  },
  data() {
    return {
      visible: false,
      localStatus: this.selectedActivity.status,
      localActivityType: this.selectedActivity.activityType,
      localSummary: this.selectedActivity.summary,
      localStartDate: this.selectedActivity.startDate,
      localDueDate: this.selectedActivity.dueDate,
      predecessor: null,
      resourcesInEditMode: {},
      filteredActivities: null,
    };
  },
  methods: {
    hideSlidebar() {
      this.$emit('update:showActivitySidebar', false);
    },
    isDate(value) {
      return isDate(value);
    },
    formatAsDay(date) {
      return format(toDate(date), 'MMM do, yyy');
    },
    distanceToNow(date) {
      return formatDistanceToNow(toDate(date), { addSuffix: true });
    },
    activityTypeIcon(activityType) {
      switch (activityType) {
        case 'COORDINATED':
          return 'pi pi-users';
        case 'INDIVIDUAL':
          return 'pi pi-user';
        default:
          return 'pi pi-user';
      }
    },
    createChangePayload() {
      return {
        playbookId: this.$store.state.assess.playbook._id,
        assessTaskId: this.$store.state.assess.playbook.assessTaskId,
        activityId: this.selectedActivity.id,
      };
    },
    submitChangeToStore(action, payload) {
      this.$store.dispatch(action, payload).then(() => {
        this.$emit('activityUpdated');
      });
    },
    toggleActivityFlag() {
      const propertyChange = this.createChangePayload();
      propertyChange.activity = this.selectedActivity;
      this.submitChangeToStore('assess/toggleActivityFlag', propertyChange);
    },
    statusModelUpdated(event) {
      this.localStatus = event;
    },
    activityTypeModelUpdated(event) {
      this.localActivityType = event;
    },
    statusChanged(event) {
      const propertyName = 'status';
      const propertyChange = this.createChangePayload();
      propertyChange[propertyName] = this.localStatus;
      this.submitChangeToStore('assess/updateActivity', propertyChange);
    },
    activityTypeChanged(event) {
      const propertyName = 'activityType';
      const propertyChange = this.createChangePayload();
      propertyChange[propertyName] = this.localActivityType;
      this.submitChangeToStore('assess/updateActivity', propertyChange);
    },
    propertyChanged(propertyName, event) {
      const newValue = event.target.innerText;
      const propertyChange = this.createChangePayload();
      propertyChange[propertyName] = newValue;
      this.submitChangeToStore('assess/updateActivity', propertyChange);
    },
    datePropertyChanged(propertyName, newValue) {
      const propertyChange = this.createChangePayload();
      propertyChange[propertyName] = format(newValue, 'yyyy-MM-dd');
      this.submitChangeToStore('assess/updateActivity', propertyChange);
    },
    richTextPropertyChanged(propertyName, newValue) {
      const propertyChange = this.createChangePayload();
      propertyChange[propertyName] = newValue;
      this.submitChangeToStore('assess/updateActivity', propertyChange);
    },
    addConditionOfCompletion() {
      const change = this.createChangePayload();
      change.condition = 'Outcome X has been delivered';
      this.submitChangeToStore('assess/addConditionOfCompletion', change);
    },
    archiveConditionOfCompletion(condition) {
      const change = this.createChangePayload();
      change.condition = 'Outcome X has been delivered';
      change.conditionId = condition.id;
      this.submitChangeToStore('assess/archiveConditionOfCompletion', change);
    },
    toggleConditionOfCompletion(condition) {
      const change = this.createChangePayload();
      change.condition = condition;
      this.submitChangeToStore('assess/toggleConditionOfCompletion', change);
    },
    updateConditionOfCompletion(condition, event) {
      const newValue = event.target.innerText;
      const change = this.createChangePayload();
      change.conditionId = condition.id;
      change.condition = newValue;
      this.submitChangeToStore('assess/updateConditionOfCompletion', change);
    },
    addNote() {
      const change = this.createChangePayload();
      change.note = 'New note...';
      this.submitChangeToStore('assess/addNote', change);
    },
    // Will currently Archive the note
    deleteNote(note) {
      const change = this.createChangePayload();
      change.noteId = note.id;
      this.submitChangeToStore('assess/deleteNote', change);
    },
    updateNote(note, event) {
      const newValue = event.target.innerText;
      const change = this.createChangePayload();
      change.noteId = note.id;
      change.note = newValue;
      this.submitChangeToStore('assess/updateNote', change);
    },
    addResource() {
      const resource = {
        name: 'Link to document',
        link: 'https://',
      };
      const change = this.createChangePayload();
      change.resource = resource;
      this.submitChangeToStore('assess/addResource', change);
    },
    archiveResource(resource) {
      const change = this.createChangePayload();
      change.resource = resource;
      this.submitChangeToStore('assess/archiveResource', change);
    },
    updateResource(resource) {
      const change = this.createChangePayload();
      change.resource = resource;
      this.submitChangeToStore('assess/updateResource', change);
    },
    filterActivities(query) {
      console.log('filtering activities...');
      console.log(query);
      this.filteredActivities = [...this.activities];
    },
  },
};
</script>

<style lang="scss" scoped>

.p-autocomplete-item-activity {
  display: flex;
  div:first-child {
    margin-right: 1rem;
  }
}
.warning {
  color: var(--color-danger);
}
.completed {
  color: var(--primary-color);
}
.lateToStart {
  color: var(--color-warning);
}
.lateToFinish {
  color: var(--color-warning);
}

.line {
    border: solid var(--primary-color);
    border-top-width: 1px;
    border-bottom-width: 0px;
    border-left: none;
    border-right: none;
    flex-grow: 1;
    height: 2px;
    margin: 1rem;
    opacity: 0;
}

.activityTypesDropDown {
  display: flex;
  align-items: center;
  i {
    margin-right: 0.4rem;
  }
}

.condition {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: space-between;
    i {
      cursor: pointer;
      font-size: 1.2rem;
      margin-right: 1rem;
    }
    .p-button {
      opacity: 0;
      margin-left: 0.5rem;
    }
  }

  .condition:hover {
    .p-button {
      opacity: 1.0;
    }
    .line {
      opacity: 1.0;
    }
  }

  .attachment {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: space-between;
    i {
      font-size: 1.2rem;
      margin-right: 1rem;
    }
    .p-button {
      opacity: 0;
      margin-left: 0.5rem;
    }
    .link {
      display: flex;
      align-items: center;
      margin-left: 2rem;
      .url {
        margin-left: 0.2rem;
      }
    }
  }

  .attachment:hover {
    .p-button {
      opacity: 1.0;
    }
    .line {
      opacity: 1.0;
    }
  }

  .discussion {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: space-between;
    // font-weight: 100;
    i {
      font-size: 1.2rem;
      margin-right: 1rem;
    }
    .p-button {
      align-self: flex-start;
      opacity: 0;
      margin-left: 0.5rem;
    }
  }

  .discussion:hover {
    .p-button {
      opacity: 1.0;
    }
    .line {
      opacity: 1.0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

</style>
