import { createWebHistory, createRouter } from 'vue-router';
import Playbook from '@/pages/playbook/Playbook.vue';
import Playbooks from '@/pages/playbooks/Playbooks.vue';
import Risks from '@/pages/risks/Risks.vue';
import RiskRegister from '@/pages/risks/components/RiskRegister.vue';
import Situation from '@/pages/risks/components/Situation.vue';
import SmartsheetCallbackHandler from '@/pages/smartsheet/SmartsheetCallbackHandler.vue';

const routes = [
  {
    path: '/',
    redirect: '/playbooks',
  },
  {
    path: '/playbooks',
    component: Playbooks,
  },
  {
    path: '/playbooks/:id',
    component: Playbook,
  },
  {
    path: '/smartsheet',
    component: SmartsheetCallbackHandler,
  },
  {
    path: '/risks',
    component: Risks,
    redirect: '/risks/register',
    children: [
      {
        path: 'register',
        component: RiskRegister,
      },
      {
        path: 'situations/:id',
        component: Situation,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
